.main-layout {
	width: 100vw;
	height: 100vh;
	padding-top: var(--header-height);
	padding-left: var(--sidebar-width);
	background: var(--bg);
	position: relative;
}
@media (min-width: 768px) and (max-width: 1199px) {
	.main-layout {
		overflow: hidden;
	}
}
.full-map {
	height: calc(100vh - var(--header-height));
	margin: 0;
	position: relative;
}
