.firedep-vehicles {
	display: flex;
	flex-direction: row;
}

.vehicles-all {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.basic-additional-feature {
	width: 54%;
}

.suits-gasi {
	width: 44%;
	margin-top: 56px;
}
.title-name {
	background-color: #fff;
	padding: 12px 20px;
	margin: 0;
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-bottom: 0;
	font-size: 15px;
	font-weight: 500;
}

.vehicles {
	width: 100%;
}

.vehicles-filters {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	gap: 20px;

	& > * {
		flex-basis: 200px;
		flex-shrink: 1;
	}
}

.vehicle-addition {
	background: #fff;
	border: 1px solid rgba(#000, 0.1);
	border-radius: 4px;
	padding: 10px;

	.addition-items-block {
		margin-top: 15px;

		.sp-row {
			.sp-col {
				display: flex;
				gap: 20px;

				> .sp-btn {
					margin: 0;

					&.sp-btn-primary {
						width: 82px;
					}
					&.sp-btn-default {
						width: 70px;
					}
				}

				.sp-trigger.sp-popover {
					.sp-btn.sp-btn-default {
						width: 70px;
					}
				}
			}
		}
	}
}
.vehicle-box,
.vehicle-addition {
	margin-bottom: 14px;
}
