.dictionaries {
	display: flex;

	.menu {
		flex-shrink: 0;
		flex-basis: 300px;

		a {
			font-weight: bold;
			display: block;
			text-decoration: none;
			height: 30px;
			line-height: 30px;
			padding: 0 5px;
			margin-right: 10px;
			color: var(--dark);
		}

		a.active {
			color: var(--primary);
		}

		.form-field {
			width: 90%;
			margin: 5px;

			.select {
				margin: 5px 0;
			}

			&:first-child label {
				margin-bottom: 14px;
			}
		}
	}
	.model {
		width: 100%;
		margin-top: 8px;
	}
}
