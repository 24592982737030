.hint-component{
	color: #0af;
	cursor: pointer;
}

.hint-component-popup {
	font-size: 12px;
	// line-height: 1.4em;
	max-width: 300px;
	box-shadow: none;
	padding: 9px;
	border-radius: 6px;
	.sp-popover-content {
		line-height: inherit;
		font-family: inherit;
	}
}