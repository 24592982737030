.raids {
    .filters {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .bottom {
            display: flex;
            gap: 10px;
        }
        margin-bottom: 20px;
    }
    .sp-table {
        tr td {
            vertical-align: middle;
            & > div {
                white-space: unset;
            }
        }
    }
}

.raid-popup {
    &.loading {
        display: flex;
        align-items: center;
        text-align: center;
        font-weight: bold;
        font-size: 18px;
        .loader {
            margin-left: 20px;
        }
    }

    .sp-popup-close-btn {
        display: none;
    }
    .raid-form {
        .form-wrapper {
            display: flex;
            overflow-y: auto;

            .new {
                width: 100%;
            }
            .section-title {
                font-weight: bold;
                margin-bottom: 10px;
            }
            .left {
                min-width: 20%;
                margin-right: 30px;
            }
            .right {
                height: 500px;
                flex-grow: 1;
                padding-right: 15px;
            }
        }
    }
    .controls {
        float: right;
    }
}

.air-raid {
    max-width: 960px;
}

.edit-raid-job-popup,
.edit-pilot-popup {
    .sp-popup-close-btn {
        display: none;
    }
}
