.address-map-tools {
	position: absolute;
	left: 50%;
	bottom: 10px;
	height: 52px;
	width: 600px;
	background: #fff;
	padding: 10px;
	border: 1px solid rgba(#000, 0.15);
	border-radius: 4px;
	z-index: 1000;
	cursor: default;
	transform: translate3d(calc(-50% - 45px), 0, 0);
	display: flex;
	gap: 10px;
	
	.address-value {
		display: flex;
		align-items: center;
	}
	
	.techfire-address-value {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		
		.other-address-btn {
			background: #0af;
			color: #fff;
			height: 20px;
			line-height: 20px;
			font-size: 11px;
			padding: 0 5px;
			border-radius: 4px;
		}
	}
}

.address-map-tip {
	position: absolute;
	left: 50%;
	bottom: 50px;
	width: 600px;
	height: 380px;
	background: #fff;
	border-radius: 4px;
	z-index: 1000;
	cursor: default;
	transform: translate3d(-50%, 0, 0);
	border: 1px solid rgba(#000, 0.15);
	
	.hdr {
		padding: 0 10px;
		height: 30px;
		line-height: 30px;
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		position: relative;
		
		.close-btn {
			position: absolute;
			right: 0;
			top: 0;
			width: 30px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			font-size: 20px;
			cursor: pointer;
			
			&:hover {
				color: #000;
			}
		}
	}
	
	.objects {
		border: 1px solid rgba(#000, 0.15);
		border-top: 0;
		height: 200px;
		padding: 10px;
		overflow: auto;
	}
}
