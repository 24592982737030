.raids-list {
  .sp-table {
    width: 100%;
    table-layout: auto;
  }
}

.popover {
  text-align: center;
  display: block;
  height: 30px;
  line-height: 28px;
  padding: 0 5px;
  border-radius: 4px;
  background: var(--primary);
  color: #fff;

  cursor: pointer;
  margin-bottom: 15px;
}
