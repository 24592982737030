.isdm-page {
  display: flex;
  flex-direction: column;
  .filters {
    margin-bottom: 10px;
    display: flex;
    gap: 10px;
  }
  & table {
    margin-top: 5px;
    border: 1px solid black;
    border-collapse: collapse;
    & td {
      border: 1px solid black;
      padding: 5px;
    }
  }
}
