.firedep {
	max-width: 1200px;
	
	.geo-edit {
		width: 960px;
		
		.edit-area {
			width: 960px;
			height: 600px;
		}
	}
}
