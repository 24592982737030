.ls5pso,
.list-112,
.statistic-112,
.techfaulty,
.lsogps40 {
	width: 1080px;
	.filter {
		margin-bottom: 0 !important;
	}
	.sp-date-picker {
		max-width: 120px !important;
	}
}
