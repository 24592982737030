.leaflet-covid {
	position: absolute;
	z-index: 1000;
	pointer-events: none;
	top: 10px;
	right: 10px;
	left: auto;

	.leaflet-control {
		float: right;
		margin-bottom: 10px;
	}
}
