.model-list {
	.filters {
		margin-bottom: 15px;
		display: flex;

		input {
			max-width: 100%;
			height: 30px;
			margin-right: 15px;
		}
	}

	table {
		td, th {
			padding: 5px 10px;
		}
		
		th {
			font-weight: normal;
			font-size: 11px;
		}
	}
	
	.table-with-pager {
		transition: all 250ms;
		
		.ui-pager {
			transition: all 250ms;
		}

		&.loading {
			table tbody {
				opacity: 0.5;
				filter: saturate(0);
			}

			.ui-pager {
				opacity: 0.5;
				pointer-events: none;
				filter: saturate(0);
			}

		}

		&.initial {
			opacity: 0;
		}

	}
}

.table-wrapper {
	max-width: 100%;
	margin-left: -5px;
	padding-left: 5px;

	table {
		transition: all 250ms;

		&.loading {
			opacity: 0.5;
			filter: saturate(0);
		}
	}
}

@media (max-width: 767px) {
	.table-wrapper {
		max-width: 100%;
		overflow-x: auto;
		margin-left: -5px;
		padding-left: 5px;

		table.ui-table {
			table-layout: auto;
		}
	}
}