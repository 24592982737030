.request {
	.header {
		display: flex;
		justify-content: space-between;
	}
}
.delivery-popup {
	&.request-changed-popup {
		min-height: 150px !important;
		.sp-popup-content {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			.action {
				margin-top: 20px;
				display: flex;
				gap: 20px;
				button {
					margin-left: 0;
				}
			}
		}
	}
}
