.case-list {
	& > .list-pane.version-2 {
		display: flex;

		.filters {
			flex-basis: 210px;
			flex-shrink: 0;
			padding: 15px 0 15px 10px;
			font-size: 12px;
			//overflow: auto;

			.block {
				& > div {
					margin-bottom: 10px;
				}

				.sp-date-picker, .date-picker {
					width: 100%;
					min-width: auto;

					input {
						height: 30px;
					}
				}


				.sp-dropdown, .drop-down {
					width: 100%;

					.hint {
						color: #aaa;
					}
				}

				.ui-checkbox {
					white-space: nowrap;
					flex-shrink: 0;
					margin-right: 15px;
				}
			}
			.date {
				display:flex;
				flex-display: row;
				justify-content: space-between;
				& > * {
					width:48% !important;
				}
			}
		}

		& > .list {
			flex-grow: 1;
		}
	}

	& > .list-pane.version-1 .filters {

		.block {
			margin-top: 10px;
			font-size: 12px;
			height: 30px;
			display: flex;
			align-items: center;
			margin-left: -5px;
			margin-right: -5px;

			& > div {
				flex-basis: 20%;
				flex-shrink: 0;
				flex-grow: 0;
				padding-left: 5px;
				padding-right: 5px;
				//background: #ddd;

				&.big {
					flex-basis: auto;
					flex-grow: 1;

					.sp-dropdown, .drop-down {
						display: inline-block;
						vertical-align: middle;
						width: 60px;
						margin-left: 5px;
						margin-right: 5px;

						.list {
							min-width: 100px;
						}
					}
				}
			}

			.date-picker {
				margin-right: 10px;
				width: 100%;
				min-width: auto;

				input {
					height: 30px;
				}
			}

			.sp-dropdown, .drop-down {
				display: inline-block;
				vertical-align: middle;
				margin-right: 10px;
				width: 100%;
				max-width: 100%;

				.hint {
					color: #aaa;
				}
			}

			.ui-checkbox {
				white-space: nowrap;
				flex-shrink: 0;
				margin-right: 15px;
			}
		}

	}
}

.city-item {
	display: flex;

	span {
		flex-grow: 1;
		padding-right: 10px;

		em {
			color: #d00;
			font-style: normal;
		}
	}

	small {
		text-align: right;
		font-size: 11px;
		color: #aaa;
	}
}
