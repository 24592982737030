.profile-page {
	.edit-avatar {
		display: flex;

		.uploaded-image {
			border-radius: 50%;
			overflow: hidden;
			cursor: pointer;
			position: relative;

			.edit-span {
				display: none;
			}

			img {
				object-fit: cover;
				pointer-events: none;
				user-select: none;
				transition: all var(--transition-timing);
			}

			&:hover {
				img {
					filter: brightness(0.7);
				}

				.edit-span {
					user-select: none;
					display: block;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					color: #fff;
					font-size: 30px;
				}
			}
		}
	}
}

.edit-avatar-popup {
	.sp-popup-content {
		display: flex;
		justify-content: center;
		align-items: center;
		.editor {
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center;
			h3 {
				margin: 10px 0 0 0;
				text-align: center;
			}
		}
		.buttons {
			input {
				display: none;
			}

			label > button:active {
				pointer-events: none;
			}
		}
	}
}
