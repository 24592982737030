.counties {
	flex-grow: 1;

	.sp-tabs {
		font-size: 12px;

		.sp-tab {
			padding: 0 13px;
		}
	}

	.scroller {
		max-height: calc(100% - 60px);
		overflow: auto;
	}

	.counties-table {
		width: 100%;
		border-collapse: collapse;
		transition: all 250ms;

		&.loading {
			//opacity: 0.5;

			tr td.total {
				//visibility: hidden;
			}

			tr.selected {
				td {
					background: transparent;
				}
			}
		}

		&.hidden {
			display: none;
			//opacity: 0.5;
		}

		tr {
			cursor: pointer;

			&:last-of-type {
				td {
					border: 0;
				}
			}

			&:hover {
				td {
					background: var(--hover);
				}
			}

			&.selected {
				td {
					background: var(--primary);

					&.name {
						color: #fff;
					}

					&.total {
						&, &:not(.zero).isolated, &:not(.zero).contacted {
							color: #fff;
						}
					}
				}
			}

			td {
				padding: 3px 5px;
				border-bottom: 1px solid var(--border);

				&.name {
					color: var(--text);
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
				}

				&.total {
					width: 25%;
					text-align: center;
					color: #d00;
					font-weight: bold;
					font-size: 16px;

					&.zero {
						color: #888;
						font-weight: normal;
						font-size: 14px;
					}

					&:not(.zero).isolated {
						color: #28c;
					}

					&:not(.zero).contacted {
						color: #d80;
					}
				}
			}
		}
	}
}
