.emergency-plan {
	display: flex;
	// height: 100%;
	.header {
		display: flex;
		
		.title {
			flex-grow: 1;
		}
		
		.controls {
			display: flex;
			justify-content: flex-end;
			gap: 10px;
		}
	}
	.left {
		width: 1040px;
		height: calc(100% - 10px);

		// overflow-y: auto;
		// padding-right: 5px;
		.resources {
			button {
				margin-bottom: 10px;
			}
		}
	}
	.right {
		.full-map {
			margin-bottom: -20px;
			padding: 20px;
			.map-right-sidebar {
				right: 27px !important;
				top: 90px !important;
			}
		}

		margin: -20px -20px -20px 0;
		flex-grow: 1;
	}
}
