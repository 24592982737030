.sc-panel.appeals-pie-chart {
	overflow: visible !important;
}



.pie-legend {
	line-height: 1.4em;
	//height: calc(1.4em * 5);
	height: calc(100% - 440px);
	overflow: auto;
	margin-top: 15px;
	
	.item {
		display: flex;
		padding-right: 10px;

		.color {
			flex-basis: 30px;
			flex-shrink: 0;

			span {
				display: inline-block;
				vertical-align: middle;
				width: 20px;
				height: 10px;
				border-radius: 3px;
				margin-bottom: 1px;
				background: #eee;
				border: 1px solid rgba(#000, 0.1);
			}
		}

		.name {
			flex-grow: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.count {
			flex-basis: 40px;
			text-align: right;
			padding-right: 10px;
			flex-shrink: 0;
			margin-left: 5px;
			color: #000;

			&.zero {
				color: #aaa;
			}
		}

		.percent {
			flex-basis: 40px;
			flex-shrink: 0;
			margin-left: 5px;
			color: #0af;

			&.zero {
				color: #aaa;
			}
		}
	}
}