.radius-selector {
	position: absolute;
	font-size: 11px;
	font-family: Roboto, sans-serif;
	right: 10px;
	bottom: 140px;
	z-index: 1000;
	user-select: none;

	.radius-btn {
		display: block;
		width: 50px;
		background: rgb(112, 146, 190);
		text-align: right;
		padding: 2px 3px;
		margin-bottom: 1px;
		color: #fff;
		cursor: pointer;

		&:hover {
			background: rgb(86, 115, 168);
		}

		&.active {
			background: var(--primary);
		}
	}
}

.heat-points-selection {
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background: rgba(#468, 0.2);

	.selection-rect {
		position: absolute;
		background: rgba(#468, 0.4);
		border: 1px solid rgba(#468, 0.6);
	}
}
