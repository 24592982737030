.appeal-card.kb-item-content {
	background: var(--main-bg, #fff);
	border-radius: 4px;
	font-size: 13px;
	position: relative;
	color: var(--text, #333);

	.appeal-loading {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 0;
		overflow: hidden;
		opacity: 0.5;
		background: rgba(#0af, 0.2);
	}

	.appeal-content {
		z-index: 100;
		padding: 0 5px 0 7px;
	}

	.top {
		font-size: 11px;
		height: 24px;
		line-height: 24px;
		display: flex;

		.id {
			flex-grow: 1;
			color: var(--secondary);

			&.closed {
				color: var(--success);
			}
		}

		.right {
			flex-grow: 1;
			text-align: right;
			color: var(--text-light);
		}
	}

	.main {
		display: flex;

		.name {
			flex-grow: 1;
			line-height: 16px;
			max-height: calc(16px * 5);
			display: -webkit-box;
			-webkit-line-clamp: 5;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.user {
			flex-basis: 24px;
			height: 24px;
			margin-left: 10px;
			display: flex;
			font-size: 11px;
			//padding-top: 5px;
			margin-bottom: 5px;

			.avatar {
				margin-right: 0;
				//pointer-events: all !important;
			}
		}
	}

	.bottom {
		display: flex;
		//background: #eee;
		line-height: 16px;
		padding: 3px 0;

		.labels {
			font-size: 11px;
			color: var(--primary);
		}

		.info {
			flex-grow: 1;
			font-size: 11px;
			color: var(--text-light);
			text-align: right;
		}
	}
}
