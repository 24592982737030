.test {

	.sp-dropdown, .drop-down {
		display: inline-block;
		vertical-align: middle;
		width: 200px;
		margin-right: 10px;
	}

}

.alarm-test {
	max-width: 960px;
}

.test-user-item {
	display: flex;
	font-size: 12px;
	align-items: center;

	img {
		width: 20px;
		height: 20px;
		margin-right: 10px;
	}
}

.test-suggest {
	width: 600px;

	input {
		background: #fffcf0;
		color: #666;
	}
}