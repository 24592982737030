.tracking-production {

	margin: 10px 0;

	.add-button {
		display: flex;
		background: #eee;
		justify-content: center;

		svg {
			cursor: pointer;
		}
	}

	// table
	.sp-table {
		padding: 24px !important;
		background: var(--white);
		overflow: hidden;

		th {
			background-color: var(--white) !important;

			.column-title {
				a {
					color: #000;
					font-weight: bold;
					font-size: 14px;
					text-decoration: none;
				}
			}
		}

		thead tr {
			height: 40px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);

			th {
				font-weight: 700;
				font-size: 14px;
				line-height: 16px;
				color: var(--black);
				vertical-align: inherit;

				.column-header {
					position: relative;

					.column-title {
						overflow: inherit;

						a {
							color: var(--black);
							text-decoration: none;
						}
					}

					.field-sort {
						position: absolute;
						left: -18px;
						color: transparent;

						svg {
							width: 16px;
							height: 16px;

							&.fa-chevron-down {
								background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAMFBMVEUAAACAgIC5ubm5ubm5ubm3t7e4uLi4uLi4uLi3t7e4uLi3t7e4uLi4uLi4uLj///9CphPcAAAAD3RSTlMAAgsWM0NES1Nuc5nZ7e6Bagj4AAAAAWJLR0QPGLoA2QAAAC1JREFUCNdjYMANWNLAwIFB5h0YHMSmiHM3EDQgM7AA9nvvFoAZTGFpBgz4AAAyFxNlsUl8nQAAAABJRU5ErkJggg==');
							}

							&.fa-chevron-up {
								background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAAMFBMVEUAAACAgIC5ubm5ubm5ubm3t7e4uLi4uLi4uLi3t7e4uLi3t7e4uLi4uLi4uLj///9CphPcAAAAD3RSTlMAAgsWM0NES1Nuc5nZ7e6Bagj4AAAAAWJLR0QPGLoA2QAAAC1JREFUCNdjYMANWNLAwIFB5h0YHMSmiHM3EDQgM7AA9nvvFoAZTGFpBgz4AAAyFxNlsUl8nQAAAABJRU5ErkJggg==');
								transform: rotate(180deg);
							}
						}
					}
				}
			}
		}

		tbody tr:not(:first-child) {
			height: 40px !important;
			font-size: 14px;
			line-height: 16px;
		}

		th,
		td {
			padding-left: 42px;
		}

		.number-value {
			color: inherit;
		}
	}
}

.production-edit {
	.delete-btn {
		height: 100%;
	}
}