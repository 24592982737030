.comments {
	.title {
		font-size: 12px;
		color: var(--secondary);
		margin-bottom: 15px;
	}
}

.comment {
	$pl: 30px;
	margin-bottom: 15px;
	display: flex;
	align-items: flex-start;

	font-size: 14px;
	line-height: 16px;

	.attachments {
		margin-top: 15px;
		.list,
		.new-files {
			margin-top: 5px;
		}
	}

	&-wrapper {
		flex-grow: 1;
		line-height: 13px;
		.content {
			padding-left: 44px;
		}
	}

	.text {
		margin: 25px 0 10px 0;
		word-break: break-word;
		position: relative;

		.comment-icon {
			position: absolute;
			left: -38px;
			width: 18px;
			height: 18px;
		}

		p {
			margin: 0;
		}

		ol,
		ul {
			margin: 0;
			padding: 0 0 0 15px;
		}

		* + ol,
		* + ul,
		ol + *,
		ul + * {
			margin-top: 1.4em;
		}
	}

	.toggle {
		margin-top: 10px;
		margin-bottom: 2px;
		display: flex;
		align-items: center;
		.recycle-btn {
			box-shadow: none !important;
			width: 10px;
			height: 10px;
		}
		& > * {
			margin-right: 10px;
		}
	}
}

// комментарии
.comment-header {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	.avatar,
	.subject {
		color: var(--primary);
		font-weight: 700;
		margin-right: 12px;
	}
	.subject {
		margin-right: 8px;
	}
	.date {
		margin-top: 3px;
		display: block;
		color: var(--dark-grey);
	}

	.discussion-item {
		& > * {
			margin-right: 5px;
		}
		.member {
			color: var(--primary);
			font-weight: 700;
			margin-right: 5px;
		}
		.status {
			margin-left: 5px;
		}
	}
}
