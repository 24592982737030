.address-registry {
	height: 100%;

	.address-single {
		height: 100%;

		.address-form {
			height: calc(100% - 50px);
			background: #fff;
			margin-top: 10px;
			
			.hdr {
				background: transparent;
			}
			
			.sidebar {
				background: transparent;
			}
		}
	}
}