.object-log-block {
	flex-basis: 400px;
	font-size: 14px;
	height: 100%;
	overflow-y: auto;
	margin-right: 35px;
	position: sticky;
	top: 0;

	.adpi-status-info {
		margin-bottom: 10px;
	}
}
