.form-block {
	margin-top: 10px;
	margin-bottom: 20px;
	//background: var(--lighter-grey);
	//padding: 15px 10px 10px 10px;
	padding: 15px 0;
	border-radius: 4px;
	//border: 1px dashed rgba(#000, 0.2);
	position: relative;

	.title {
		position: absolute;
		margin-top: -25px;
		margin-left: -5px;
		padding: 0 5px;
		//background: var(--lighter-grey);
		background: var(--bg);
		border-radius: 4px;
		font-size: 12px;
	}
}

.custom-action-bar {
	margin-top: 20px;
	display: flex;
	column-gap: 20px;
	.clean-btn {
		box-shadow: unset !important;
	}
}
