.map-mode-switch.sc-panel-wrapper {
	background: var(--bg);
	height: 25px;

	.sc-panel {
		padding: 0 10px;
		height: 25px;
		line-height: 25px;
		font-size: 11px;

		a {
			text-decoration: none;
			display: inline-block;
			vertical-align: middle;
			margin-left: 5px;
			background: #ccc;
			color: #000;
			height: 16px;
			line-height: 13px;
			border: 1px outset rgba(#fff, 0.5);
			margin-bottom: 1px;
			text-align: center;
			width: 40px;
			border-radius: 3px;

			&.active {
				background: var(--primary);
				border: 1px inset #8cf;
				color: #fff;
			}
		}
	}
}
