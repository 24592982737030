.fires-timeline {
	font-family: 'Roboto', sans-serif;
	letter-spacing: -0.03em;
	font-variant-numeric: tabular-nums;
	position: absolute;
	border-radius: 10px;
	bottom: 10px;
	left: 10px;
	height: 60px;
	right: 400px;
	padding: 0 15px;
	background: rgba(#000, 0.2);
	z-index: 100;
	cursor: default;
	display: flex;
	align-items: center;
	gap: 15px;

	.timeline-datepicker {
		flex-shrink: 0;
		background: #fff;
		height: 30px;
		line-height: 30px;
		border-radius: 15px;
		cursor: pointer;
		text-align: center;
	}

	.timeline-timeselect {
		flex-grow: 1;
		margin-top: -5px;
		color: rgba(#fff, 0.8);
	}

	.day-shift {
		display: inline-block;
		width: 30px;
		vertical-align: bottom;
		margin: 0 10px;
		//background: #eee;

		&:hover {
			color: #0af;
		}
	}
}
