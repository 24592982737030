.appeals-kanban {
	//background: #eee;
	height: 100%;

	.bottom {
		.attachments-count {
			color: var(--primary);
			font-size: 10px;
		}
	}
}
