.sentries {
	max-width: 1200px;
	
	.actions-bar {
		display: flex;
		align-items: center;
		gap: 20px;
		margin-bottom: 20px;
		
		& > div {
			font-size: 14px;
			cursor: pointer;
			height: 40px;
			line-height: 40px;
			padding: 0 5px;
			border-bottom: 1px solid rgba(#000, 0.15);
			
			&:hover {
				background: var(--selected-bg);
			}
			
			&:last-child {
				border-bottom: 0;
			}
		}
	}
	
	.sentry-tab {
		.tab-badge {
			background: #aaa;
			font-size: 11px;
		}
	}
	
	.ui-checkbox .ui-checkbox-toggle {
		svg {
			* {
				fill: var(--primary);
			}
		}
	}

	.sentries-list {
	}
	
	.first-hint {
		max-width: 400px;
		padding: 15px;
		background: #fffcf0;
		border: 1px solid rgba(#000, 0.2);
		font-size: 14px;
		border-radius: 10px;
	}
	
	.sentry-edit {
		.actions {
			margin: 20px 0;
		}
		
		.personnel-and-vehicles {
			.is-saving {
				pointer-events: none;
				filter: saturate(0);
				opacity: 0.7;
			}
		}
	}
}
