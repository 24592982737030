.notifications {
	display: flex;
	flex-direction: column;
	gap: 20px;
	position: fixed;
	top: 50px;
	right: 100px;
	width: 290px;
	z-index: 5000;
	font-size: 14px;

	@keyframes notification {
		0% {
			opacity: 0;
		}
		25% {
			opacity: 1;
		}
		75% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}

	.notification {
		padding: 12px 16px;
		animation: notification 10s;
		border-radius: 4px;
		color: var(--white);

		& > p {
			margin: 0;
		}

		&.success {
			background-color: var(--success);
		}

		&.error {
			background-color: var(--danger);
		}

		&.warning {
			background-color: var(--warning);
		}

		&.alarm {
			@keyframes noFadeOutNotification {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}
			animation: noFadeOutNotification 1s;
			background-color: var(--danger);
		}

		&._adpi {
			background-color: var(--adpi-message);
		}

		&._112 {
			cursor: pointer;
			background-color: var(--_112-message);
		}

		.notification-message-text {
			width: 100%;
			display: flex;
			gap: 12px;
			align-items: flex-start;

			svg {
				*[stroke] {
					stroke: white;
				}
			}
		}

		.buttons {
			display: flex;
			gap: 12px;
			width: 100%;
			margin-top: 12px;

			button {
				margin: 0;
				border: none;
				font-weight: 500;
				width: 124px;
				font-size: 14px;
			}

			.notification {
				&-cancel-button {
					background-color: rgba(255, 255, 255, 0.2);
					color: var(--bg);
				}

				&-open-button {
					color: var(--dark);
				}
			}
		}
	}
}
