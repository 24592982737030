.main-sidebar {
	position: absolute;
	left: 0;
	top: var(--header-height);
	bottom: 0;
	width: var(--sidebar-width);
	background: var(--bg);
	z-index: 2000;
	box-shadow: 1px 0 5px rgba(#000, 0.15);
	padding-left: calc(var(--sidebar-width) - var(--sidebar-btn-size));
	user-select: none;
	overflow: hidden;
}

@media (min-width: 768px) and (max-width: 1199px) {
	.main-sidebar {
		padding: 0;
		width: auto;
	}
}