.sp-btn {
	--default-border: var(--primary);
	--primary-bg: var(--primary);
	--primary-text: #fff;

	&.dark {
		--primary-bg: var(--deep-dark);
		--primary-text: var(--lighter-grey);
		--disabled-bg: var(--lighter-dark);
		--disabled-text: var(--grey);
		--default-border: transparent;
		min-width: 2em;
	}
}
