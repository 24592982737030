.delivery-card {
	width: 100%;
	&__header {
		height: 42px;
		background-color: var(--dark);
		display: flex;
		align-items: center;
		padding: 9px 14px;
		border-radius: 8px 8px 0px 0px;
		.title {
			color: #fff;
			margin-left: 15px;
			font-weight: 700;
			font-size: 16px;
			line-height: 19px;
		}
		.icon {
			width: 24px;
			height: 24px;
			svg {
				width: 100%;
				height: 100%;
			}
		}
	}
	.content {
		border-radius: 0px 0px 8px 8px;
		background-color: #fff;
	}
}
