.request {
	.main-info {
		.form-field {
			flex: unset;
			margin-bottom: unset !important;
		}
		.send-message {
			margin-top: 20px;
		}
		& > * {
			column-gap: 20px;
			margin-bottom: 20px;
			& > * {
				column-gap: 20px;
				margin-bottom: 20px;
			}
		}
		.supplies {
			& > * {
				margin-bottom: unset;
				column-gap: unset;
			}
		}
		.main {
			& > * {
				display: flex;
				flex-wrap: wrap;
				column-gap: 20px;
				margin-bottom: 20px;
			}

			.top {
				display: flex;
				.form-field {
					min-width: 200px !important;
					width: 200px !important;
				}
				.checkbox {
					display: flex;
					flex-direction: row-reverse;
					justify-content: flex-end;
					align-items: center;
					column-gap: unset;
					margin-top: 20px;
					.ui-checkbox {
						margin-right: 0;
						margin-top: 0;
						width: 30px !important;
					}
					label {
						margin-bottom: 0;
					}
				}
			}
			.bottom {
				display: flex;
				.form-field {
					min-width: 420px !important;
					width: 420px !important;
				}
			}
		}
		.members {
			.member {
				display: flex;
				display: flex;
				flex-wrap: wrap;
				.form-field {
					min-width: 420px !important;
					width: 420px !important;
				}
				.delete-btn {
					margin-top: 25px;
				}
			}
		}
	}
}

.request-supply-popup {
	.periods {
		.period-title {
			font-weight: bold;
			margin: 10px 0 20px 0;
		}
		button {
			margin-bottom: 10px;
		}
	}
}
