.emergency-plan {
	.agreement {
		display: flex;
		width: 1040px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		.plan-files {
			padding: 20px;
		}
		.members-list {
			.member-comment {
				font-weight: bold;
				cursor: pointer;
				color: var(--primary);
			}
		}
	}
}

.request-member {
	.sp-popup-header {
		background: var(--primary);
	}
}

.textarea:focus {
	border-color: var(--primary);
}
