.last-update {
	text-align: center;
	flex-basis: 90px;
	flex-shrink: 0;
	padding: 10px;
	line-height: 26px;

	strong {
		font-size: 24px;
	}
}
