body {
	.form-field {
		margin-bottom: 10px;

		& > label:first-child {
			color: var(--primary);
		}

		label {
			margin-bottom: 2px;
		}
	}
}
