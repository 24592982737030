.techfires-module .reports-page {
	display: flex;
	flex-direction: column;
	height: 100%;
	.content {
		display: flex;
		gap: 20px;
		height: 100%;
		overflow-y: hidden;
		.menu {
			display: flex;
			flex-direction: column;
			filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
			overflow-y: auto;
			height: 100%;
			flex-shrink: 0;
			flex-basis: 400px;
			a {
				background: var(--white);
				display: flex;
				align-items: center;
				text-decoration: none;
				min-height: var(--row-height);
				color: var(--black);
				padding: 12px 24px;

				&.active {
					background: var(--black);
					color: var(--white);
				}

				&:hover {
					background: var(--dark-grey);
					color: var(--white);
				}
			}
		}
		.report {
			width: 100%;
			overflow-y: auto;
			// display: flex;
			.filter {
				display: flex;
				gap: 20px;
				margin-bottom: 20px;
			}
		}
	}
}

.preview-table, .preview-table * {
	border-collapse: collapse;
	border: 1px solid;
}