.waybill-single {
	.firedep-list {
		& > .actions {
			margin-bottom: 15px;
			display: flex;
			align-items: center;
			gap: 10px;
		}

		.firedep {
			background: #fff;
			margin-bottom: 15px;
			border-radius: 4px;
			border: 1px solid rgba(#000, 0.15);

			.header {
				line-height: 40px;
				padding: 0 15px;
				font-weight: bold;
				font-size: 14px;
				border-bottom: 1px solid rgba(#000, 0.15);
				display: flex;
				justify-content: space-between;
				align-items: center;
				.left {
					display: flex;
					gap: 20px;
					align-items: center;
					.name {
						flex-grow: 1;

						.reason {
							font-weight: normal;
							margin-left: 10px;
							font-size: 12px;
						}
					}

					.accepted {
						color: var(--primary);
						font-weight: normal;
						line-height: 24px;
						padding: 2px 10px;
						border-radius: var(--border-radius);
						background-color: var(--super-light-grey);
					}
				}
				.actions {
					flex-shrink: 0;
					margin: 0;
				}
			}

			.errors {
				padding: 10px;
			}

			.vehicles {
				padding: 10px 15px;

				.sp-table {
					td,
					thead th {
						padding: 5px;
					}
				}
			}
		}
	}

	.actions {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 20px;

		.sp-btn {
			margin: 0;
		}
	}

	.errors {
		& > div {
			padding: 10px;
			border-radius: 4px;
			background: #ffe0e0;
			border: 1px solid rgba(#000, 0.15);
			margin-bottom: 10px;
		}
	}
}
