.single-cargo {
	display: flex;
	background-color: var(--super-light-grey);
	height: 100%;
	overflow: auto;

	.left-panel {
		display: flex;
		flex-basis: 60%;
		flex-direction: column;
		padding: 20px;
		overflow: auto;

		.header {
			display: flex;
			justify-content: space-between;

			.header-btn {
				cursor: pointer;
				min-width: var(--height);
				height: var(--height);
				background: #ffffff;
				box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
				border-radius: 5px;
				border: none;
			}
		}

		.delivery-card .icon {
			svg path {
				stroke: var(--primary);
			}
		}
	}

	.full-map {
		flex-basis: 40%;

		.sp-tab .tab {
			display: flex;
			align-items: center;
			font-weight: bold;
		}
	}
}
