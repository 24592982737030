.sp-date-picker {
	&.green {
		--date-picker-border-radius: 8px;
		--date-picker-border-color: var(--primary);
		--date-picker-active-border-color: var(--primary);
		--date-picker-hover-border-color: var(--primary);

		min-width: 110px;
		&:hover {
			filter: contrast(130%);
		}

		.clear-btn {
			display: none;
		}
		&::before {
			content: "";
			position: absolute;
			right: 10px;
			top: 5px;
			width: 18px;
			height: 18px;
			background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19 4H5C3.89543 4 3 4.89543 3 6V20C3 21.1046 3.89543 22 5 22H19C20.1046 22 21 21.1046 21 20V6C21 4.89543 20.1046 4 19 4Z' stroke='%2362B257' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M16 2V6' stroke='%2362B257' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M8 2V6' stroke='%2362B257' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M3 10H21' stroke='%2362B257' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
		}

		&.active > input {
			border-bottom-left-radius: 8px;
			border-bottom-right-radius: 8px;
		}
	}
	&.dark {
		--date-picker-text: var(--lighter-grey);
		--date-picker-bg: var(--deep-dark);
		--date-picker-secondary-text: var(--light-grey);
		--date-picker-secondary-bg: var(--lighter-dark);
		--date-picker-tab-text: var(--light-grey);
		--date-picker-tab-bg: var(--dark);
		--date-picker-tab-hover-text: var(--light-grey);
		--date-picker-tab-hover-bg: var(--dark-grey);
		--date-picker-border-color: var(--dark);
		--date-picker-hover-bg: var(--deep-dark);
		--date-picker-hover-text: var(--light-grey);
		--date-picker-hover-border-color: var(--deep-dark);
		--date-picker-dropdown-bg: var(--deep-dark);
		--date-picker-dropdown-border-color: var(--dark);
		--date-picker-active-bg: var(--deep-dark);
		--date-picker-active-text: var(--light-grey);
		--date-picker-active-border-color: var(--deep-dark);
		--date-picker-border-radius: var(--border-radius);
		--date-picker-shadow: var(--lighter-grey);
		--date-picker-btn-text: var(--lighter-grey);
		--date-picker-btn-bg: var(--deep-dark);
		--date-picker-btn-hover-bg: var(--dark);
		--date-picker-ui-font-size: 14px;

		--calendar-day-width: 3em;
		--calendar-day-height: 2.5em;
		--calendar-day-hover-text: #fff;
		--calendar-day-hover-bg: rgba(var(--primary-rgb), 0.2);
		--calendar-day-hover-border-color: var(--primary);
		--calendar-today-text: rgba(var(--primary-rgb), 0.9);
		--calendar-today-bg: rgba(var(--primary-rgb), 0.2);
		--calendar-today-border-color: rgba(var(--primary-rgb), 0.4);

		--clock-size: 18em;
		--clock-hover-text: #fff;
		--clock-hover-bg: var(--primary);
		--clock-selected-bg: var(--primary);
		--clock-selected-color: #fff;
		--clock-minute-bg: var(--lighter-dark);
		--clock-minute-text: var(--light-grey);
		--color-small-minute-bg: var(--dark-grey);
		--color-small-minute-text: transparent;
		--clock-am-hour-bg: var(--lighter-dark);
		--clock-am-hour-text: var(--light-grey);
		--clock-pm-hour-bg: var(--deep-dark);
		--clock-pm-hour-text: var(--light-grey);
		--clock-value-bg: var(--deep-dark);
		--clock-value-text: var(--light-grey);
		--clock-arrow-color: var(--primary);
	}

	
}
