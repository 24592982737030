.errands-root {
	padding: 15px;

	.stats-control {
		margin-bottom: 30px;

		* + * {
			margin-left: 10px;
		}

		.hint {
			color: #aaa;
		}
	}

	.set-color {
		display: inline-block;
		vertical-align: baseline;
		width: 10px;
		height: 10px;
		background: #eee;
		margin-right: 10px;
		border-radius: 50%;
	}

	.errands-chart {
		display: flex;
		align-items: center;
		gap: 50px;

		.legend {

			strong {
				color: #000;
			}
		}
	}

	.errand-orgs {
		margin-top: 30px;
		width: 960px;

		table {
			width: 960px;
			margin: 0;
			border-collapse: collapse;
			font-size: 12px;
			line-height: 1.2em;

			th, td {
				border: 1px solid #ddd;
				vertical-align: top;
				padding: 5px;
			}

			th {
				font-weight: normal;
				text-align: left;
				font-size: 11px;
				background: #eee;
				border-bottom-width: 3px;
			}

			td {
				text-align: center;
				cursor: default;
			}

			td.name {
				max-width: 500px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				text-align: left;
				cursor: text;
			}

			tr {
				&:hover td {
					background: #f2f4f8;
				}
			}
		}
	}
}