.contragent {
	.main {
		column-gap: 20px;
		.top {
			& > * {
				width: 420px;
				max-width: 420px;
			}
			display: flex;
			column-gap: 40px;
			padding-bottom: 20px;
			border-bottom: 2px solid var(--light-grey);
		}
		.bottom {
			display: flex;
			column-gap: 40px;
			.left,
			.right {
				.title {
					font-weight: 700;
					margin: 20px 0;
				}
				width: 420px;
			}
			.contacts {
				display: flex;
				column-gap: 20px;
			}
		}
	}
}
