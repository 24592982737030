.history-log {
	height: 100%;
	overflow-y: auto;
	padding-bottom: 20px;
	.item {
		display: flex;
		margin-bottom: 10px;
		.date {
			display: inline-block;
			color: var(--primary);
			width: 140px;
		}
		.user {
			font-weight: 700;
			margin-right: 5px;
		}
		.subject {
			margin: 0 5px;
			font-weight: 700;
		}
		.actions {
			display: flex;
			flex-direction: column;
			gap: 5px;
		}
	}
}
