.route-menu {
	display: flex;
	flex-direction: column;
	filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.1));
	overflow-y: auto;
	flex-shrink: 0;
	flex-basis: 400px;

	a {
		background: var(--white);
		display: flex;
		align-items: center;
		text-decoration: none;
		min-height: var(--row-height);
		color: var(--black);
		padding: 12px 24px;
		padding-left: 50px;
		font-size: 14px;

		&.active {
			background: var(--black);
			color: var(--white);
		}

		&:hover {
			background: var(--dark-grey);
			color: var(--white);
		}
	}

	.submenu {
		display: flex;
		flex-direction: column;

		&.hidden {
			.title {
				svg {
					transform: rotate(-90deg);
				}
			}

			.items {
				display: none;
			}
		}

		.title {
			font-size: 14px;
			font-weight: 500;
			text-transform: uppercase;
			display: flex;
			gap: 12px;
			align-items: center;
			background-color: var(--white);
			min-height: var(--row-height);
			color: var(--black);
			padding: 12px 20px;
			cursor: pointer;

			&.admin-active {
				color: var(--primary);
			}

			&:hover {
				background-color: var(--light-grey);
			}

			svg {
				transition: all 120ms;
			}

			*[fill] {
				fill: var(--primary);
			}
		}

		.items {
			margin-left: 25px;
			border-top: 1px solid var(--grey);
			border-bottom: 1px solid var(--grey);
			a {
				padding-left: 12px;
			}
		}

		&:last-child {
			.items {
				border: none;
			}
		}
	}
}
