.statistics {
	.mode-select {
		margin-bottom: 10px;
	}

	.items-list {
		overflow: auto;
		height: calc(100% - 40px);
	}

	.category {
		display: flex;
		//padding-right: 10px;

		.color {
			flex-basis: 30px;
			flex-shrink: 0;

			span {
				display: inline-block;
				vertical-align: middle;
				width: 20px;
				height: 10px;
				border-radius: 3px;
				margin-bottom: 1px;
				background: #eee;
				border: 1px solid rgba(#000, 0.1);
			}
		}

		.name {
			flex-grow: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.period {
			flex-basis: 150px;
			//background: #eee;
			text-align: right;
			padding-right: 10px;
			flex-shrink: 1;
			margin-left: 5px;
			color: #000;

			&.zero {
				color: #aaa;
			}
		}
	}

	.heat-map {
		text-align: center;

		svg {
			path {
				cursor: pointer;

				&:hover {
					stroke: #d00;
					stroke-width: 2px;
				}
			}
		}

		.info {
			margin-top: 15px;

			em {
				font-style: normal;
				color: #0af;
			}
		}
	}
}