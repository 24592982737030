.dashboard-section-select {
	// min-width: 320px;
	border: none;
	line-height: 44px;
	height: unset;
	font-size: 30px;
	font-weight: 700;
	background: var(--light-blue-bg);

	.filter {
		.filter-content {
			padding: unset;
		}

		.filter-arrow {
			padding-top: 5px;
			font-size: 30px;
			svg {
				path {
					d: path(
						"M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"
					) !important;
					fill: black;
				}
			}
		}
	}

	.sp-dropdown-items {
		background-color: #fff;
		border: 1px solid var(--primary);
		border-radius: 8px;
		font-weight: normal;
		position: absolute;
		right: 0;
	}
}

.fires-dashboard-page {
	background: var(--light-blue-bg);
	font-family: Roboto, sans-serif;

	.div-table {
		overflow: auto;
		width: 100%;
		table {
			margin-right: 10px;
		}
	}

	.action-panel {
		max-width: 1600px;
		margin: 20px 0;

		.nav {
			display: inline-block;
			vertical-align: middle;
			background: var(--primary);
			width: 24px;
			height: 24px;
			line-height: 27px;
			margin: 0 3px;
			border-radius: 5px;
			text-align: center;
			color: #fff;
			font-size: 12px;
			cursor: pointer;

			&:hover {
				filter: contrast(130%);
			}
		}

		.actions {
			display: flex;
			flex-direction: row;
			margin-bottom: 20px;
			gap: 20px;
			align-items: center;

			.date-select {
				display: flex;
				gap: 10px;

				button {
					padding: 0 !important;
					width: var(--element-height) !important;
					height: calc(var(--element-height) - 0.5px) !important;
				}

				.right-chevron {
					transform: rotate(180deg);
					margin-left: 1px;
				}

				.left-chevron {
					margin-right: 1px;
				}
			}
		}
		.right {
			flex-grow: 1;
			display: flex;
			align-items: center;
			.download-button {
				border-radius: 8px;
			}
		}
		.description {
			font-size: 15px;
			line-height: 18px;
			color: var(--dark-grey);
			width: 100% !important;

			.filter {
				display: inline-block;

				.filter-content {
					display: inline-block;
					padding: unset;
				}
			}
		}
	}

	.dashbox {
		display: inline-block;
		vertical-align: top;
		width: 300px;
		height: 110px;
		margin-right: 30px;
		//margin-bottom: 30px;
		background: #eee;
		border: 1px solid rgba(#000, 0.2);
		padding: 10px;
		border-radius: 10px;
		text-align: center;

		h2 {
			margin-bottom: 5px;
		}

		strong {
			font-size: 64px;
			line-height: 64px;
			font-weight: 700;
		}

		&.total strong {
			color: #a00;
		}

		&.total-area strong {
			color: #000;
			font-size: 48px;
			line-height: 52px;
		}
	}

	.fires-chart {
		width: 100%;
		margin: 30px 0;
		max-width: 800px;

		.legend {
			font-size: 12px;
			margin-bottom: 10px;

			.title {
				font-size: 16px;
				margin-right: 20px;
			}

			.color {
				display: inline-block;
				vertical-align: middle;
				margin: 0 5px 2px 0;
				background: #ccc;
				width: 30px;
				height: 14px;
				border-radius: 4px;

				& + * {
					margin-left: 20px;
				}

				&.red {
					background: #d44;
				}

				&.green {
					background: #4c0;
				}
			}
		}

		.fires-chart-count,
		.fires-chart-area {
			width: 100%;
			height: 420px;
			overflow: auto;
		}
	}
}

@media (min-width: 768px) and (max-width: 1199px) {
	.fires-dashboard {
		.actions {
			.left {
				width: 100%;
				flex-basis: auto !important;

				.nav {
					display: none;
				}
			}

			.right {
				button {
				}
			}
			.empty-sections {
				display: none;
			}
		}

		.div-table {
			overflow: auto;
			width: 100%;
			height: calc(100% - 110px);
		}
	}
}
