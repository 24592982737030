.composite-toolbar {
	display: flex;
	align-items: center;
	column-gap: 10px;
	pointer-events: all;

	& > * {
		height: var(--height) !important;
	}
}
