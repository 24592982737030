.sc-panel.fire-card-wrapper {
    .fire-card-map-container {
        flex-basis: 40%;
        flex-grow: 0;
        height: calc(100% + 20px);
        flex-shrink: 0;
        //padding-right: 10px;
        margin: -10px;

        &.map-maximized {
            flex-grow: 1;
        }

        .fires-timeline {
            left: 10px;
            right: 10px;
            //width: 90%;
        }
    }

    .fire-card-map {
        background: #eee;

        .map-draw-controls {
            bottom: 80px;
        }

        .map-scale-container {
            right: 50%;
            margin-right: -150px;
        }
    }
}
