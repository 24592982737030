.reserve {
	.header {
		display: flex;
		justify-content: space-between;
	}
	.main-info {
		width: 420px;
		.form-field {
			flex: unset;
			margin-bottom: unset !important;
		}

		.category-select,
		.date {
			width: calc(50% - 10px);
		}

		.sp-date-picker {
			min-width: 100%;
		}

		& > * {
			display: flex;
			column-gap: 20px;
			margin-bottom: 20px;

			& > * {
				column-gap: 20px;
				margin-bottom: 20px;
			}
		}
	}
	.movement {
		width: 1000px;
		.reserve-info {
			display: flex;
			flex-direction: row;
			column-gap: 20px;
			margin-bottom: 20px;
		}
		.sp-col {
			margin-right: 1px;
		}

		.name {
			min-width: 420px;
		}
	}
}
