.adpi-filter {
	.settings {
		display: flex;
		flex-direction: column;
		row-gap: 5px;
	}
	h4 {
		margin-bottom: 2px;
	}
}

.map-fire-alarm-object-popup {

}