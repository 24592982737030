.text-field {
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;

	.label {
		color: var(--grey);
	}

	.value {
		margin-top: 5px;
		color: var(--dark);
	}
}

.dotted-text-field {
	p {
		margin: 0;
		overflow: hidden;

		&::after {
			content: '......................................................................................................................................................................................................................................................................................';
			display: block;
			white-space: nowrap;
			overflow: hidden;
		}

		.name {
			color: var(--primary);
			float: left;
			margin-right: 3px;
		}

		.value {
			float: right;
			margin-left: 3px;
		}
	}
}