.customer-info {
	.main {
		display: flex;
		flex-direction: column;
		column-gap: 20px;
		& > * {
			max-width: 400px !important;
		}
	}
}
