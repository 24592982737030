.techfires-module {
	.notify-form {
		max-width: 1200px;
		display: flex;
		gap: 40px;

		.main-fields {
			flex-grow: 1;
		}
		
		.geo-edit {
			flex-basis: 500px;
			flex-shrink: 0;
			
			.edit-area {
				min-width: 500px;
			}
		}
	}
}

.notify-buttons-controls {
	margin-top: 20px;
}
