.cases {
	flex-grow: 1;
	flex-shrink: 1;

	h2 {
		font-size: 14px;
		margin-bottom: 15px;
	}

	.scroller {
		max-height: calc(100% - 30px);
		//background: #eee;
		overflow: auto;
	}

	.cases-table {
		width: 100%;
		margin-left: -5px;
		border-collapse: collapse;
		font-size: 12px;

		tr {
			cursor: pointer;

			&:last-of-type {
				td {
					border: 0;
				}
			}

			&:hover {
				td {
					background: var(--hover);
				}
			}

			td {
				padding: 3px 5px;
				border-bottom: 1px solid var(--border);

				&.address {
					.statuses {
						font-size: 12px;

						span {
							&:before {
								content: '';
								display: inline-block;
								vertical-align: middle;
								margin-right: 3px;
								width: 8px;
								height: 8px;
								border-radius: 50%;
								margin-bottom: 2px;
								background: #aaa;
							}

							&.confirmed:before {
								background: var(--confirmed);
							}

							&.recovered:before {
								background: var(--recovered);
							}

							&.contacted:before {
								background: var(--contacted);
							}

							&.infdep:before {
								background: var(--infdep);
							}

							&.observator:before {
								background: var(--observator);
							}

							&.self:before {
								background: var(--self);
							}
						}

					}
				}
			}
		}
	}
}
