.sources-stats {
	.item {
		display: flex;
		padding: 2px 0;

		.icon {
			flex-basis: 24px;
			flex-shrink: 0;

			img {
				width: 16px;
				height: 16px;
			}

			span {
				display: inline-block;
				vertical-align: middle;
				width: 16px;
				height: 16px;
				border-radius: 3px;
				margin-bottom: 1px;
				background: #eee;
				border: 1px solid rgba(#000, 0.1);
			}
		}

		.name {
			flex-grow: 1;
			//background: #eee;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.count {
			flex-basis: 50px;
			text-align: right;
			padding-right: 10px;
			flex-shrink: 1;
			margin-left: 5px;
			color: #000;

			&.zero {
				color: #aaa;
			}
		}
	}
}