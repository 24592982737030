.address-search {
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	position: relative;
	
	.not-selected {
		position: absolute;
		right: 5px;
		top: 5px;
		background: #eee;
		border-radius: 4px;
		font-size: 11px;
		color: #d00;
		padding: 0 10px;
		height: 20px;
		line-height: 20px;
	}

	& > input {
		width: 100%;
		height: 30px;
		
		&::placeholder {
			color: #000;
		}

		&:focus {
			&::placeholder {
				color: #aaa;
			}
		}
	}
	
	.address-value {
		width: 100%;
		height: 30px;
		line-height: 30px;
		border: 1px solid rgba(#000, 0.2);
		display: flex;
		align-items: center;
		background: #fff;
		border-radius: 4px;
		cursor: pointer;
		padding: 0 10px;
		font-size: 14px;
		
		svg {
			margin-right: 5px;
			margin-top: -2px;
			
			* {
				fill: var(--primary);
			}
		}
	}
}

.sp-trigger-popup.address-search-popup {
	$width: 400px;
	$height: 250px;
	min-width: $width;
	height: $height;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	transform: translate3d(0, -1px, 0);
	background: #fff;
	color: #000;
	border: 1px solid #ccc;
	text-align: left;
	box-shadow: 0 3px 3px rgba(#000, 0.15);

	.empty {
		padding: 10px;
	}
	
	.suggestions {
		width: 100%;
		overflow: auto;
		height: $height;
		
		.suggestion {
			cursor: pointer;
			height: 30px;
			line-height: 30px;
			border-bottom: 1px solid #ddd;
			padding: 0 5px;
			margin-left: -5px;
			font-size: 13px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			
			&:hover {
				background: var(--hover);
			}
			
			&:last-child {
				border: 0;
			}
			
			.badge {
				display: inline-block;
				vertical-align: middle;
				font-size: 12px;
				text-align: center;
				color: #fff;
				width: 30px;
				height: 16px;
				line-height: 16px;
				margin-right: 5px;
				margin-bottom: 2px;
			}
			
			&.case {
				.badge {
					color: #d80;
				}
				
				small {
					color: #aaa;
					font-size: 12px;
				}
			}
			
			&.db {
				.badge {
					color: #0af;
				}
			}
			
			&.fias {
				.badge {
					color: #80a;
				}
			}
		}
	}
}
