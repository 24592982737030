.reports-page {
  height: calc(100% - 40px);

  & > div {
    display: flex;
    flex-direction: row;
    gap: 20px;

    height: 100%;
    .report {
      overflow: auto;
      width: 100%;

      .filters {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .row {
          width: 100%;
        }
      }
    }

    & table {
      margin-top: 5px;
      border: 1px solid black;
      border-collapse: collapse;
      & td {
        border: 1px solid black;
        padding: 5px;
      }
    }
  }
}
