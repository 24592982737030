.delivery-btn {
	&.delete-btn {
		cursor: pointer;

		min-width: var(--height);
		height: var(--height);
		background: #ffffff;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
		border-radius: 5px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 150ms ease-out;
		&:hover {
			transform: translate(0, -1px);
			svg {
				*[stroke] {
					stroke: red;
				}
			}
		}
		span {
			margin-left: 13px;
			color: var(--dark-grey);
		}
	}
}

.add-row-button {
	width: 100% !important;
	background-color: var(--white) !important;
	color: var(--black) !important;
	font-size: 14px !important;
	border:none !important;
	height: var(--height);
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: #F9F9FF !important;
		box-shadow: unset !important;
	}
	
	svg {
		margin-left: 10px;
		[fill] {
			fill: grey
		}
	}
}
