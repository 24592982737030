.appeals-root {
	background: var(--light-grey);
}

.leaflet-appeals {
	position: absolute;
	z-index: 1000;
	pointer-events: none;
	top: calc(var(--sidebar-btn-size) + 15px);
	right: 300px;
	left: auto;

	.leaflet-control {
		float: right;
		margin-bottom: 10px;
	}
}

.appeal-system-select {
	width: 240px;
}

.admin-content {
	h1 {
		font-size: 16px;
		margin-bottom: 15px;
	}
}

.dictionaries-root {
	display: flex;
	gap: 20px;
	.dictionaries-menu {
		display: flex;
		flex-direction: column;
		gap: 10px;
		min-width: 150px;
	}
}
