.weather-tip {
	position: absolute;
	font-family: 'Roboto', sans-serif;
	transform: translate(-50%, -56px);
	transform-origin: 50% bottom;
	font-size: 12px;
	pointer-events: none;
	height: 60px;
	cursor: default;
	z-index: 90;

	&.hidden {
		display: none;
	}

	.weather-tip-content {
		padding: 5px 10px;
		height: 30px;
		line-height: 20px;
		border-radius: 15px;
		background: #fff;
		border: 1px solid rgba(#000, 0.3);
		white-space: nowrap;
	}

	.weather-tip-color {
		display: inline-block;
		background: #eee;
		vertical-align: baseline;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		margin-right: 5px;
		opacity: 0.7;
	}

	.weather-tip-text {
		display: inline-block;
		vertical-align: baseline;
	}

	.weather-tip-leg {
		height: 30px;
		background: no-repeat url('/static/tip-leg.svg') center;
		//position: absolute;
		//background: #d00;
		//width: 11px;
		//height: 11px;
		//bottom: 5px;
		//left: calc(50% - 5px);
		//border-radius: 50%;
		//border: 1px solid rgba(#000, 0.5);
	}
}