.request {
	.agreement {
		display: flex;
		column-gap: 20px;
		.left {
			width: 1040px;
			display: flex;
			flex-direction: column;
			gap: 10px;
		}
		.right {
			.categories {
				label {
					display: block;
					margin-bottom: 6px;
					color: var(--dark-grey) !important;
				}

				div {
					margin-bottom: 5px;
				}
			}
			.form-field {
				overflow: hidden;
				word-break: break-all;
				text-overflow: ellipsis;
			}
		}
	}
}

.request-member {
	.sp-popup-header {
		background: var(--primary);
	}
}



.textarea:focus {
	border-color: var(--primary);
}
