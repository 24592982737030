.profile-page {
    height: 100%;
    .sp-col {
        max-width: 300px !important;
    }
    h3 {
        margin-bottom: 10px !important;
    }

    .password {
        margin-bottom: 10px;
        &-hint {
            color: var(--primary) !important;
        }
        position: relative;
        svg {
            cursor: pointer;
            position: absolute;
            top: 10px;
            right: 10px;
            font-size: 16px;
            color: var(--primary);
        }
    }
    .role-select {
        max-width: 630px !important;
        width: 100%;
        height: auto;
        padding: 5px 0;

        .filter .filter-content {
            .show-value {
                padding: 0 !important;
                word-wrap: break-word;
                white-space: normal;
            }
        }
    }
}
