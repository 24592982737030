.contragent-suggestions {
    width: 100%;
}

.sp-suggestions-list {
    .suggestion-item {
        height: auto;
        .suggestion {
            display: grid;
            grid-template-columns: 1fr;
            grid-row-gap: 5px;
            padding: 5px;
            width: 100%;
            .name-with-opf {
                font-weight: bold;
                line-height: 15px;
            }
            .suggestion-footer {
                display: flex;
                justify-content: space-between;
                color: var(--weak-text);
            }
        }
    }
}
