.module-menu {
    width: calc(var(--sidebar-width) + 20px);
    height: calc(100vh - var(--header-height));
    overflow: auto;

    a.sidebar-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--sidebar-btn-size);
        height: var(--sidebar-btn-size);
        background: var(--bg);
        color: var(--dark);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        text-decoration: none;
        text-align: center;

        &:first-child {
            margin-top: 10px;
        }

        .icon {
            display: inline-block;
            margin-bottom: 10px;
            //background: #eee;
            width: 28px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            font-size: 14px;

            svg *[fill] {
                fill: var(--dark);
            }

            svg *[stroke] {
                stroke: var(--dark);
            }

            #transport_data *[fill] {
                fill: none;
            }
        }

        .title {
            //background: #ac0;
            font-size: 10px;
            font-weight: bold;
            padding: 0 5px;
            line-height: 1.1em;
        }

        &:hover {
            color: var(--dark);
            background: var(--lighter-grey);

            .icon {
                color: var(--primary);

                svg *[fill] {
                    fill: var(--primary);
                }

                svg *[stroke] {
                    stroke: var(--primary);
                }
            }
        }

        &.active {
            background: var(--primary);
            color: #fff;

            .icon {
                color: #fff;

                svg *[fill] {
                    fill: #fff;
                }

                svg *[stroke] {
                    stroke: #fff;
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .module-menu {
        width: auto;

        a.sidebar-btn {
            width: calc(var(--sidebar-width));
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            &:first-child {
                margin-top: 0;
            }
        }
    }
}
