.address-search {
	display: inline-block;
	vertical-align: middle;
	min-width: 140px;
	position: relative;

	.not-selected {
		position: absolute;
		right: 5px;
		top: 5px;
		background: #eee;
		border-radius: 4px;
		font-size: 11px;
		color: #d00;
		padding: 0 10px;
		height: 20px;
		line-height: 20px;
	}

	& > input {
		&::placeholder {
			color: #000;
		}

		&:focus {
			&::placeholder {
				color: #aaa;
			}
		}
	}

	.dropdown {
		$width: 500px;
		$height: 350px;
		position: absolute;
		top: calc(100% - 1px);
		left: 0;
		min-width: $width;
		width: 100%;
		height: $height;
		z-index: 1200;
		border-radius: 4px;
		overflow: hidden;
		white-space: nowrap;
		background: #fff;
		color: #000;
		border: 1px solid #ccc;
		text-align: left;
		box-shadow: 0 3px 3px rgba(#000, 0.15);

		.ui-pager {
			margin: 10px 0 5px 10px;
		}

		.empty {
			padding: 10px;
		}

		.suggestions {

			.suggestion {
				cursor: pointer;
				height: 30px;
				line-height: 30px;
				border-bottom: 1px solid #ddd;
				padding: 0 5px;
				margin-left: -5px;
				font-size: 13px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;

				&:hover {
					background: var(--hover);
				}

				&:last-child {
					border: 0;
				}

				.badge {
					display: inline-block;
					vertical-align: middle;
					font-size: 12px;
					text-align: center;
					color: #fff;
					width: 30px;
					height: 16px;
					line-height: 16px;
					margin-right: 5px;
					margin-bottom: 2px;
				}

				&.case {
					.badge {
						color: #d80;
					}

					small {
						color: #aaa;
						font-size: 12px;
					}
				}

				&.db {
					.badge {
						color: #0af;
					}
				}

				&.fias {
					.badge {
						color: #80a;
					}
				}
			}
		}
	}
}
