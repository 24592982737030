.emergency-module {
	.municipality-edit {
		margin: -20px;
		display: flex;
		.left-panel {
			display: flex;
			flex-direction: column;
			padding: 20px;
			min-width: 800px;
			width: 100%;

			.cities {
				.upload {
					margin-bottom: 0 !important;
				}
				.upload + .list {
					margin-top: 5px !important;
				}
			}

			&-header {
				font-weight: 700;
				font-size: 18px;
				line-height: 21px;
				margin-bottom: 20px;

				span {
					margin-left: 10px;
				}
			}

			.edit-form-buttons {
				display: flex;
				flex-direction: row;
				gap: 16px;
				margin-top: auto;
				margin-left: auto;

				button {
					display: flex;
					align-items: center;
					cursor: pointer;
					padding: 5px 10px;
					border: none;
					border-radius: 5px;
					height: 34px;

					svg {
						margin-right: 10px;
					}
				}

				.close-button,
				.save-button {
					background: #37b813;
					color: #ffffff;
				}

				.delete-button {
					background: #ffffff;
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
				}
			}

			h1 {
				display: none;
			}

			hr {
				margin: 15px 0;
			}
		}

		.full-map {
			.sp-tab .tab {
				display: flex;
				align-items: center;
				font-weight: bold;
			}
		}
	}
}

.city {
	.sp-row {
		align-items: end;
	}
}
