.sp-tabs {
	--tab-text: #888;
	--tab-bg: transparent;
	--tab-parent-rgb: 255, 255, 255;
	--tab-container-border-color: rgba(0, 0, 0, 0.2);
	--tab-border-width: 3px;
	--tab-border-color: transparent;
	--tab-hover-text: #000;
	--tab-hover-border-color: var(--light-grey);
	--tab-active-text: #333;
	--tab-active-border-color: var(--primary);
	--tab-active-bg: transparent;

	.sp-tab {
		padding: 0 25px 0 0;
		//margin-right: 5px;

		&:first-child {
			//margin-left: -10px;
		}
	}
}