.fires-module {
    .emergency-situation-page {
        height: 100%;
        display: flex;
        flex-direction: column;
        #table {
            height: unset;
            flex-grow: 1;
        }
    }
}
.edit-emergency-situation {
    form {
        .form-content {
            .left {
                min-width: 450px;
            }
            display: flex;
            flex-direction: row;
            gap: 20px;
        }
    }
}
