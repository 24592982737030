.main-header {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	height: var(--header-height);
	color: var(--dark);
	display: flex;
	align-items: center;
	z-index: var(--header-z);
	background: var(--bg);
	box-shadow: 0 1px 3px rgba(#000, 0.15);
	user-select: none;

	.left {
		flex-basis: 50%;
		padding-left: 10px;
		display: flex;
		align-items: center;

		.site-title {
			position: relative;
			padding-right: 30px;
			width: 500px;
			a {
				display: flex;
				align-items: center;
				height: var(--header-height);

				&,
				&:hover {
					text-decoration: none;
				}

				& > .logo {
					width: 80px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				& > .title {
					color: var(--grey);
					font-size: 16px;
					font-weight: bold;
					padding-left: 15px;
					height: var(--header-height);
					line-height: var(--header-height);
					text-transform: uppercase;
				}
			}

			&:after {
				content: "";
				position: absolute;
				right: 15px;
				top: calc((var(--header-height) - 50px) / 2);
				width: 1px;
				height: 50px;
				background: var(--light-accent);
			}
		}

		@media (min-width: 768px) and (max-width: 1199px) {
			.site-title {
				position: relative;
				padding-right: 30px;
				width: auto;

				.title {
					display: none;
				}
			}
		}
	}

	.right {
		flex-basis: 50%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding-right: 15px;
		color: var(--dark);
		font-weight: 500;
		gap: 20px;

		.user-avatar {
			margin-right: 10px;
			border: 1px solid #888888;
		}

		.admin-link,
		.user-link {
			a {
				text-decoration: none;
			}
		}

		.signOut:hover {
			filter: invert(65%) sepia(34%) saturate(7407%) hue-rotate(72deg) brightness(101%) contrast(85%);
		}

		.language-switch {
			span {
				cursor: pointer;
			}

			.sp-selected {
				font-weight: bold;
			}

			.sp-dropdown {
				font-size: 12px;
				width: 80px;
			}
		}
	}

	a {
		color: var(--dark);

		&:hover {
			color: var(--primary);
		}
	}
}
