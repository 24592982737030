.water-supply-filter {
	.radio + .radio {
		margin-left: 10px;
	}
	.settings {
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
}

.water-supply-data {
	max-height: 300px;
	overflow: auto;
}