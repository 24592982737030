.delete-btn {
	display: inline-block;
	vertical-align: middle;
	padding: 0 5px;
	cursor: pointer;

	svg * {
		fill: #000;
	}

	&:hover {
		svg * {
			fill: #d00;
		}
	}
}
