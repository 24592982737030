.address-filters {
	width: 1400px;
	display: flex;
	flex-direction: column;
	gap: 10px;

	> div {
		display: flex;
		gap: 20px;

		.sp-btn {
			margin: 0;
		}
	}

	.record-select {
		width: 170px !important;
	}

	.input-name {
		width: 270px;
	}
}
