.total {
	text-align: center;
	flex-basis: 110px;
	flex-shrink: 0;

	h1 {
		display: block;
		font-size: 18px;
		font-weight: normal;
		margin: 5px 0 10px 0;
	}

	strong {
		display: block;
		font-size: 64px;
		line-height: 60px;
		color: #e00;
		font-weight: normal;
	}

	&.isolated {
		strong {
			color: #28c;
		}
	}

	&.contacted {
		strong {
			color: #d80;
		}
	}
}
