.notify-popover-label {
	text-align: center;
	display: block;
	height: 30px;
	line-height: 28px;
	padding: 0 5px;
	border-radius: 4px;
	background-color: var(--primary);
	color: #fff;
	cursor: pointer;
	margin-bottom: 15px;
}

.notify-popup {
	.top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
		.repicients {
			.count {
				font-weight: bold;
				font-size: 16px;
				margin-right: 20px;
				.mass {
					color: #d00;
					font-size: 16px;
					line-height: 16px;
				}
			}
		}
		.notify-template {
			width: 200px;
			& > * {
				margin-bottom: 0;
			}
		}
	}
	.confirm-code {
		margin-bottom: 10px;
		display: flex;
		align-items: center;

		.generated-code {
			margin: 0 10px;
			font-weight: bold;
			font-size: 16px;
			color: var(--primary);
		}
	}
	.action {
		display: flex;
		align-items: center;
		.confirm-input {
			margin-left: 20px;
			width: 65px;
		}
	}

	.no-records,
	.loading-popover {
		text-align: center;
	}

	.sp-popup-header {
		background-color: var(--primary);
	}
}

.notify-template-popover {
	width: 600px;
	margin: 0 10px 10px 10px;

	.filters {
		padding: 0 15px;
	}

	.sp-table {
		tr td {
			vertical-align: middle;
			padding: 5px 3px;
			line-height: unset;
			width: 100%;

			& > div {
				white-space: unset;
			}
		}

		&:not(.full) tr:last-child {
			&,
			td {
				border: 0;
			}
		}
	}
}
