.sc-panel.fire-card-wrapper {
    display: flex;
    height: 100%;

    .fire-card {
        height: 100%;
        flex-grow: 1;
        padding-right: 15px;
        display: flex;

        &.map-maximized {
            flex-grow: 0;
        }

        .loader {
            margin: auto auto;
        }

        .fire-card-content {
            width: 100%;
            display: flex;

            & > .sp-tabs {
                padding-top: 5px;

                &.map-maximized {
                    display: none;
                }

                .sp-tabs-content {
                    height: calc(100% - 110px);
                    overflow-y: auto;
                }
            }

            //* SIDEBAR
            .fire-sidebar {
                box-shadow: 1px 0 5px rgba(#000, 0.15);
                margin: -10px 20px -10px -15px;

                display: flex;
                flex-direction: column;
                width: 200px;

                &.map-maximized {
                    margin: -10px -5px -10px -15px;
                }

                .title {
                    padding: 15px;
                    min-height: 50px;
                    font-size: 16px;
                    font-weight: bold;
                    margin: 0 0 20px 0;
                }

                .dynamic-list {
                    overflow-y: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    .dynamic {
                        display: flex;
                        height: 26px;
                        cursor: pointer;
                        font-weight: bold;
                        white-space: pre;
                        .date {
                            margin-left: 3px;
                            display: flex;
                            font-family: monospace;
                            & > * {
                                margin-right: 10px;
                            }
                        }
                        //.delete-row {
                        //    opacity: 0;
                        //}
                        &:hover {
                            .delete-row {
                                opacity: 0.7;
                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }
                        &.active {
                            color: var(--primary);
                        }

                        svg {
                            height: 17px;
                        }
                    }
                }
            }

            .card-actions {
                margin-bottom: 15px;
                padding-bottom: 15px;
                border-bottom: 1px solid #ddd;
                display: flex;
                align-items: center;
                justify-content: space-between;
                & > * {
                    margin-right: 15px;
                    & > button {
                        margin-right: 15px;
                    }
                }
            }

            @media (min-width: 768px) and (max-width: 1199px) {
                .card-actions {
                    button span:nth-child(2) {
                        display: none;
                    }
                }
            }

            .card-form {
                overflow: auto;
                margin: 0 10px 10px 0;
                font-size: 12px;

                input {
                    font-size: 12px;
                    height: 30px;
                }

                .row {
                    margin-bottom: 15px;
                    margin-left: 0;
                    margin-right: 0;

                    & > div {
                        padding-left: 0;
                        padding-right: 30px;
                        max-width: none;
                    }
                }
            }

            .columns {
                display: flex;
                gap: 30px;
                //padding-right: 30px;

                .column {
                    flex-grow: 1;
                    flex-basis: 0;
                }
            }
        }
    }

    .string-value,
    .number-value {
        font-size: 12px;
    }
}

.changed-fire-warning-popup {
    height: 150px;
    .sp-popup-close-btn {
        display: none;
    }
    .sp-popup-content {
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.status-color {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #ddd;
    margin: 0 3px;
    display: inline-block;
}

.forestry-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 1px;

    tr td:not(:first-child) {
        text-align: center;
    }

    thead tr {
        background: #f2f2f2;
    }

    tbody tr:nth-child(even) {
        background: #f2f2f2;
    }
}

.panel {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;

    .record-select {
        width: 100% !important;
        max-width: 260px;
    }

    .box1 {
        grid-column-start: 2;
        grid-column-end: 4;

        h2 {
            margin-bottom: 26px;
        }

        input {
            width: 60px;
        }
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .panel {
        grid-template-columns: repeat(1, 1fr);

        .box1 {
            grid-column-start: auto;
            grid-column-end: auto;
        }
    }
}
