.techfire-create {
	max-width: 1600px;

	& > .actions {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 20px;
		
		.sp-btn {
			margin: 0;
		}
	}

	.form-and-waybill {
		display: flex;
		gap: 30px;
		
		.edit {
			flex-basis: 50%;
			
			.techfire-create-map {
				margin-top: 20px;
				height: 400px;
				border: 1px solid rgba(#000, 0.15);
			}
		}
		
		.create-waybill {
			flex-basis: 50%;
			padding-top: 25px;
		}
	}
}
