body, body * {
	--scrollbar-thumb: var(--grey);
	--scrollbar-thumb-hover: var(--dark-grey);
	--scrollbar-track: var(--light-grey);
	--scrollbar-size: 10px;

	scrollbar-width: thin;
	scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);

	&:hover {
		scrollbar-color: var(--scrollbar-thumb-hover);
	}

	&::-webkit-scrollbar {
		width: var(--scrollbar-size);
		height: var(--scrollbar-size);
	}

	&::-webkit-scrollbar-track {
		background-color: var(--scrollbar-track);
		border-radius: calc(var(--scrollbar-size) / 2);
	}

	&::-webkit-scrollbar-thumb {
		background: var(--scrollbar-thumb);
		border-radius: calc(var(--scrollbar-size) / 2);

		&:hover {
			background: var(--scrollbar-thumb-hover);
		}
	}
}
