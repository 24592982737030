.add-row {
	height: 40px;
	padding: 6px 0;
	background: rgba(238, 238, 238, 0.4);
	text-align: center;

	.sp-dropdown-items {
		.active {
			background-color: var(--primary);
		}
	}

	.add-btn {
		cursor: pointer;
		border-radius: 50%;
		box-shadow: 0 1px 2px rgba(#000, 0.05);
		transition: all 150ms;

		&:hover {
			transform: scale(1.1, 1.1);
			box-shadow: 0 2px 3px rgba(#000, 0.15);
		}
	}

	&.disabled {
		pointer-events: none;
		filter: saturate(0);
	}
}
.delete-row {
	height: 18px;
	width: 18px;
	color: #d00;
	opacity: 0.7;
	cursor: pointer;
	&:hover {
		opacity: 1;
	}
}

.cancel-button {
	color: var(--dark-grey) !important;
	background: var(--lighter-grey) !important;
	svg {
		color: var(--dark-grey) !important;
		font-size: 14px !important;
	}
	&:hover {
		filter: none !important;
	}
}

.copy-button,
.cancel-button {
	font-size: 14px !important;
	padding: 0 1em !important;
	min-height: 2.4285714em;
	height: 2.4285714em;
	line-height: calc(2.4285714em - 2px) !important;
	&:disabled {
		border-color: rgba(0, 0, 0, 0) !important;
	}
}

button.icon-btn {
	display: inline-flex !important;
	align-items: center !important;
	span {
		white-space: nowrap;
	}
}
