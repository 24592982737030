.progress-bar {
	height: 24px;
	width: 100%;
	background-color: #e0e0de;
	border-radius: 50px;

	.filler {
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		height: 100%;
		border-radius: inherit;

		.label {
			padding: 5px;
			color: white;
			font-weight: bold;
		}
	}
}
