.lading-bills {
	.path {
		display: flex;
		column-gap: 20px;
		& > * {
			max-width: 200px;
			min-width: 200px;
		}
	}

	.deliveryDate {
		min-width: 200px;
		max-width: 200px;
		margin-bottom: 20px;
	}
	.totalReleased {
		width: 420px;
	}

	.lading-bills-list {
		display: flex;
		flex-direction: column;
		.lading-bill-card {
			width: 1010px;
			padding-bottom: 20px;
			border-bottom: 2px solid var(--light-grey);
			margin-top: 20px;
			margin-right: 90px;

			.document-info {
				display: flex;
				column-gap: 20px;
				width: 420px;
			}

			.organization {
				width: 640px;
				display: flex;
				column-gap: 20px;
				align-items: center;

				.name {
					min-width: 420px;
					max-width: 420px;
				}
			}
		}
		.supplies {
			margin: 20px 0;
		}
	}

	.request-track {
		width: 600px;
	}
}
