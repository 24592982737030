.range-date-picker {
    display: flex;
    flex-direction: row;
    gap: 10px;

    .right-chevron {
        transform: rotate(180deg);
        margin-left: 1px;
    }

    .left-chevron {
        margin-right: 1px;
    }

    .sp-dropdown {
        margin-right: unset !important;
    }

    button {
        padding: 0 !important;
        width: var(--element-height) !important;
        height: calc(var(--element-height) - 0.5px) !important;
    }
}
