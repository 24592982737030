/* Pop */
@-webkit-keyframes hvr-pop {
	50% {
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}
}
@keyframes hvr-pop {
	50% {
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}
}
.hvr-pop {
	display: inline-block;
	vertical-align: middle;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	box-shadow: 0 0 1px rgba(0, 0, 0, 0);
}
.hvr-pop:hover, .hvr-pop:focus, .hvr-pop:active {
	-webkit-animation-name: hvr-pop;
	animation-name: hvr-pop;
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}
