.members-avatars {
	display: flex;
	.more {
		display: flex;
		flex-direction: column;
		font-size: 13px;
		font-family: "Roboto", sans-serif;
		overflow: hidden;
	}
	.avatars {
		margin-right: 5px;
		.no-avatar {
			:hover {
				background: initial !important;
			}
		}
	}
}

.member-avatar,
.member-avatar-popup {
	.sp-avatar {
		border: 2px solid #fff !important;
		line-height: 2em !important;
	}
}

.member-avatar-popup {
	display: flex;
	white-space: nowrap;
	column-gap: 10px;

	.info {
		flex-grow: 1;
		align-self: center;

		.organization {
			color: var(--secondary);
		}
	}
}
