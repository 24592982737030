.emergency-plan {
	.main-info {
		.sp-col {
			margin-right: 0px !important;
		}
		.form-field {
			flex: unset;
			margin-bottom: unset !important;
		}

		& > * {
			& > * {
				column-gap: 20px;
				margin-bottom: 20px;
			}
		}
	}
}

.plan-member-select {
	.render-member {
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
}
