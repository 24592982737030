.sc-panel.appeals-counties {
	table {
		width: 100%;
		border-collapse: collapse;

		th {
			padding: 2px 5px;
			text-align: left;
			font-weight: normal;
			font-size: 11px;
			background: #eee;
			color: #000;
		}

		tbody {
			tr {
				cursor: pointer;

				td {
					font-size: 12px;
					padding: 3px 5px;
					border-top: 1px solid rgba(#000, 0.15);
				}

				&:hover {
					td {
						background: #eee;
					}
				}

				&:first-child td {
					border: 0;
				}
			}
		}
	}

	.no-counties {
		padding: 10px 5px;
		color: #aaa;
	}
}
