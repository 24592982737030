

.sc-panel.case-list {
	display: flex;
	//height: calc(100vh - 50px);
	//margin: -15px;
	padding: 0;

	& > .list-pane {
		flex-basis: 50%;
		background: var(--lighter-grey);

		.filters {
			overflow-y: auto;
		}

		.pager {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
	}

	& > .case-pane {
		background: var(--lighter-grey);
		flex-basis: calc(50% + 15px);
		overflow: auto;
		display: flex;
		flex-direction: column;
		padding: 15px;
	}

	& > .list-pane.version-2 > .list {
		.list-container {
			height: calc(100% - 70px);

			table {
				margin-left: -10px;

				tr {
					td:first-child,
					th:first-child {
						padding-left: 15px;
					}
				}
			}
		}
	}

	& > .list-pane > .list {
		height: 100%;
		padding: 15px;

		.top {
			display: flex;
			align-items: center;

			& > div {
				margin-left: 15px;
			}

			input {
				display: inline-block;
				vertical-align: middle;
				margin-left: 10px;
				flex-grow: 1;
			}

			.file-label {
				margin-left: 10px;
			}

			.file-input {
				display: none;
			}
		}

		.list-container {
			height: calc(100% - 190px);
			overflow: auto;

			table {
				transition: all 250ms;

				&.is-loading {
					opacity: 0.5;
					filter: saturate(0);
				}

				.address-fio {
					.address {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.fio {
						font-size: 11px;
						height: 16px;
						color: #888;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.date-type {
					small {
						color: #888;
					}
				}
			}
		}
	}

	.case-map-div {
		flex-basis: 360px;
		flex-shrink: 0;
		margin-bottom: 20px;
		display: flex;

		&.is-picking {
			.case-map {
				border-color: #0af;
				cursor: default;
			}
		}

		.case-map {
			flex-basis: 550px;
			flex-shrink: 1;
			flex-grow: 0;
			background: #eee;
			border: 1px solid #ccc;
			position: relative;

			&.maximized {
				position: fixed;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: 1000;
			}

			.maximize-btn {
				position: absolute;
				left: 10px;
				top: 10px;
				z-index: 600;
				cursor: pointer;
			}

			.legend {
				position: absolute;
				z-index: 600;
				background: rgba(#fff, 0.9);
				color: #000;
				padding: 3px 5px;
				font-size: 11px;
				bottom: 10px;
				left: 10px;
				border: 1px solid rgba(#000, 0.2);
				border-radius: 4px;

				.disc {
					display: inline-block;
					vertical-align: middle;
					margin-right: 5px;
					background: #bdf;
					border: 2px solid #8b0;
					border-radius: 50%;
					width: 12px;
					height: 12px;

					&.medved {
						background: #fc8;
					}

					&.violation {
						background: #eee;
						border-color: #d00;
					}
				}
			}
		}

		.address-tools {
			flex-grow: 1;
			padding-left: 15px;
			font-size: 12px;

			label {
				font-size: 11px;
			}

			& > div {
				margin-bottom: 10px;
			}

			.id {
				margin-bottom: 0;
				color: #aaa;
			}

			.address-name {
				line-height: 1.4em;

				small {
					display: block;
					color: #0af;
					font-size: 11px;
				}
			}

			.location-edit {
			}

			.tracks-filter {
				margin-top: 15px;
				font-size: 11px;

				& > div {
					margin-bottom: 10px;
				}

				.date-picker {
					width: 80px;
					min-width: 80px;
					font-size: 12px;

					input {
						height: 24px;
					}
				}

				button {
					margin-left: 10px;
				}

				.track-legend {
					display: inline-block;
					vertical-align: middle;
					margin-right: 10px;
					background: #ccc;
					border-radius: 50%;
					width: 12px;
					height: 12px;

					&.medved {
						background: #fc8;
					}
				}
			}

			.error {
				margin-top: 15px;
				font-size: 11px;
				line-height: 1.4;
				color: #d00;
			}

			.lon-lat-input {
				.input {
					display: inline-block;
					vertical-align: top;
					margin-right: 5px;

					label {
						display: block;
						font-size: 11px;
						color: #0af;
					}

					input {
						width: 80px;
						height: 24px;
					}
				}
			}
		}
	}

	.details {
		flex-grow: 1;
		font-size: 12px;

		.notify-message-list {
			.top {
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 0 2px;
				margin-bottom: 10px;
			}
			.sp-table {
				tr td {
					vertical-align: middle;
					padding: 5px 3px;
					line-height: unset;
					width: 100%;

					& > div {
						white-space: unset;
					}
				}

				&:not(.full) tr:last-child {
					&,
					td {
						border: 0;
					}
				}
			}
		}

		.delete-case {
			cursor: pointer;
			margin-left: 15px;
		}

		.form-field {
			label {
				margin-bottom: 0;
			}

			& > .address-search {
				width: 100%;
			}
		}

		.phone-input {
			display: flex;

			input {
				flex-grow: 1;
				margin-right: 10px;
			}
		}

		.case-logs {
			width: 100%;
			margin-bottom: 15px;
			font-size: 12px;

			& > .list {
				margin-top: 5px;
				margin-bottom: 5px;
			}

			.case-log {
				width: 100%;
				background: #f0f0f0;
				padding: 5px;
				border-bottom: 1px solid #ddd;
				display: flex;
				align-items: center;

				.date-picker {
					flex-basis: 110px;
					flex-grow: 0;
					min-width: auto;

					input {
						width: 110px;
					}
				}

				.address-search {
					flex-grow: 1;
					margin-left: 5px;
				}

				.sp-dropdown,
				.drop-down {
					flex-basis: 140px;
					margin-left: 5px;
					width: 140px;

					.hint {
						color: #aaa;
					}
				}

				.ui-checkbox {
					margin-left: 5px;
				}

				.actions {
					flex-basis: 30px;
					flex-shrink: 1;
					display: inline-block;
					vertical-align: middle;
					text-align: right;

					a + a {
						margin-left: 10px;
					}

					.ui-popover-wrapper {
						margin-left: 10px;
						color: #0af;
						cursor: pointer;

						&:hover {
							color: #000;
						}
					}
				}

				&.current {
					background: #e0e8ff;
				}

				&.future {
					background: #fc0;

					&:not(.none) {
						.isolation .filter {
							border: 1px solid #d00;
						}
					}
				}
			}
		}

		.case-actions {
			.duplicate-btn {
				float: right;
			}
		}

		.operator-select {
			display: inline-block;
			vertical-align: middle;
			line-height: 15px;
			margin-left: 5px;
			font-size: 11px;

			a {
				display: inline-block;
				vertical-align: middle;
				height: 18px;
				line-height: 18px;
				padding: 0 8px;
				border-radius: 9px;
				color: #aaa;

				&.selected {
					background: #aaa;
					color: #fff;
					pointer-events: none;
					text-decoration: none;
				}
			}
		}
	}
}

.popover-hint {
	color: #0af;
	margin-left: 5px;
}

.line-30 {
	line-height: 30px;
}
