.appeals-topbar {
	position: absolute;
	top: var(--header-height);
	left: var(--sidebar-width);
	right: 0;
	height: var(--sidebar-btn-size);
	background: var(--dark);
	color: #fff;
	z-index: calc(var(--panel-z) + 100);
	display: flex;
	align-items: center;
	padding-left: 15px;
	padding-right: 15px;

	& > *, & > .sp-btn {
		margin-right: 15px;
	}

	.category-select {
		width: 420px;

		&.sp-dropdown-list {
			.sp-dropdown-items {
				.sp-dropdown-item:first-child {
					color: #888;
				}
			}
		}
	}

	.date-picker {
		width: 80px;
		min-width: 80px;
		margin-right: 10px;
	}
}

.category-select.sp-dropdown-list {
	.sp-dropdown-items {
		.sp-dropdown-item:first-child {
			color: var(--dark-grey);
		}
	}
}
