.appeal-form {
	.category {
		color: #0af;
		margin-bottom: 10px;
	}

	.date, .status, .system {
		strong {
			color: #0af;
			font-weight: normal;
		}
	}

	.title {
		margin-top: 15px;
	}

	.text {
		margin: 15px 0;
	}
}