.techfires-module .techfires-note {
	table {
		border-collapse: collapse;
		
		th, td {
			border: 1px solid #888;
			padding: 5px;
			background: #fff;
			
			&.vert {
				writing-mode: vertical-lr;
				transform: scale(-1);
			}
		}
		
		tr {
			&.numbered, &.division-total td {
				background: #fff000;
			}

			&.head-org td {
				background: #0ad;
				text-align: center;
				font-weight: bold;
			}

			td.value {
				text-align: center;
			}

			&.firedep {
				td:first-child {
					background: #0f0;
				}
				
				&.empty td:first-child {
					background: #d00;
					color: #fff;
				}
			}
		}
	}
}
