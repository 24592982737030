.firedep-popup {

	& > div {
		margin-bottom: 10px;
	}
	
	.firedep-data {
		max-height: 210px;
		overflow: auto;
	}
}