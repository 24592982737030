.bottom-cards {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;
	margin-top: 20px;
}

.card-status,
.card-transporter,
.card-update {
	.content {
		padding: 20px;
		height: 100%;
	}
}

.card-status {
	.statuses {
		margin-top: 24px;
		.name {
			font-weight: 700;
			font-size: 14px;
			line-height: 16px;
		}

		.date,
		.city {
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			color: #7a7a7a;
		}
	}
}

.card-transporter {
	.transporter-items label {
		color: #b8b8b8 !important;
	}
}

.card-update {
	.update-item {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;

		.action {
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
		}

		.date {
			font-weight: 400;
			font-size: 14px;
			line-height: 16px;
			color: #7a7a7a;
		}
	}
}
