// обертка, сама по себе не расширяет родительский .delivery-requests
.requests-kanban-container {
	flex-grow: 1;
	flex-shrink: 1;
	position: relative;
}

// контейнер канбана, вынужденно вписывающийся в обертку .requests-kanban-container
.requests-kanban {
	--list-width: 280px;

	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;

	.kb-list-info {
		display: none !important;
	}
	.kb-list,
	.kb-items {
		width: calc(var(--list-width) + 8px) !important;
		margin-right: 2px !important;

		.kb-item {
			overflow-x: scroll !important;
			// height: 173px;
			//width: auto !important;
		}

		.hdr {
			padding: 0 18px !important;
			width: var(--list-width) !important;
			height: 36px !important;
			border: none !important;
			margin-bottom: 16px !important;
			.name,
			.count {
				font-weight: 700;
				color: #fff !important;
				font-size: 14px;
			}

			.actions {
				display: flex;
				align-items: center;
				.kb-btn {
					width: 16px !important;
					height: 16px !important;
					margin-left: 10px;
				}
			}
		}
	}
}

// класс карточки канбана должен быть на верхнем уровне css, чтобы он применялся при перетаскивании
.kanban-card {
	background-color: #fff;
	color: var(--dark-grey);
	border-left-width: 5px !important;
	padding: 8px 8px 12px 18px;
	min-height: 173px;
	display: flex;

	.request-kanban-card {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.top {
			display: flex;
			justify-content: space-between;
			.last-udpate {
				color: black;
				opacity: 0.4;
			}
		}

		.type {
			color: var(--dark-grey);
		}

		.name {
			color: #393e46;
			font-weight: 700;
			font-size: 14px;
			line-height: 16px;
			margin: 5px 0;
		}

		.customer {
			color: #7a7a7a;
			margin-bottom: 8px;
		}

		.positions {
			color: black;
		}

		.categories {
			display: flex;
			flex-wrap: wrap;
			margin: 10px 0;

			& > * {
				margin-bottom: 5px;
			}
		}
	}
}
