.techfires-registry {
	height: 100%;
	overflow: auto;
	
	.form-field {
		input:disabled, textarea:disabled {
			color: var(--dark);
		}
		
		textarea {
			font-size: 14px;
			padding: 7px 10px;
		}
	}
}

.techfires-toolbar {
	margin-bottom: 20px;
}

.date-mode-select-techfires .filter .filter-content .show-value {
	padding-top: 0;
	padding-bottom: 0;
}

.techfires-filters {
	background: none;
	display: flex;
	gap: 20px;
	
	& > * {
		flex-basis: 120px;
	}
}
