.search-icon-input {
	display: flex;
	align-items: center;
	border: 1px solid transparent !important;
	border-radius: 5px;
	background-color: var(--bg) !important;
	padding: 0 10px;
	width: 505px;
	height: var(--height);
	input {
		width: 100%;
		border: none;
		background: transparent;
		&:hover {
			background: transparent;
		}
	}
	&:hover {
		border-color: var(--primary) !important;
	}
}
