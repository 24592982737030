.techfire-single {
	max-width: 960px;

	.main-actions {
		margin-top: 10px;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
		gap: 20px;
	}

	.ui-form h3 {
		margin: 10px 0 5px 0;
	}
}

.casualty-card {
	padding: 10px;
	background: #fff;
	border: 1px solid rgba(#000, 0.15);
	margin-bottom: 1em;
}
