.module-select {
	--dropdown-border: transparent;
	--dropdown-bg: #f2f4f0;
	--dropdown-selected-bg: var(--primary);

	width: 320px;
	margin-right: 15px;
	color: #000;
	font-size: 15px;
	text-transform: uppercase;
	font-weight: bold;

	.module-item,
	.sp-dropdown-item {
		display: flex;
		align-items: center;
		padding: 0 5px;

		svg {
			width: 22px;
			flex-basis: 22px;
			text-align: center;
			margin-right: 20px;
			//margin-top: -1px;
		}

		&.selected {
			svg * {
			}
		}
	}
}
