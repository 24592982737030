.geo-edit {
	.draw-controls {
		height: 30px;
		margin-bottom: 10px;
	}

	.edit-area {
		display: flex;
		min-width: 700px;
		height: 400px;
		//gap: 15px;

		.geo-edit-map {
			flex-grow: 1;
			background: #eee;
			border: 1px solid rgba(#000, 0.15);
		}

		.json-preview {
			flex-basis: 240px;
			flex-shrink: 0;
			background: #eee;
			overflow: auto;
			border: 1px solid rgba(#000, 0.15);
			border-left: 0;
			padding: 5px;

			pre {
				font-size: 11px;
				margin: 0;
			}
		}
	}

	&.maximized {
	}
}
