#sp-upload {
	position: absolute;
	background: #0af;
	color: #fff;
	font-size: 14px;
	width: 170px;
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
	z-index: 1;
	right: 0;
}
