.selected-address {
	.address-name {
		margin-bottom: 15px;
	}

	.cases {
		font-size: 12px;
		font-family: 'Roboto', sans-serif;
		border-top: 1px solid rgba(#000, 0.2);

		a {
			display: flex;
			padding: 5px 10px;
			margin: 0 -10px;
			color: #555;
			text-decoration: none;
			border-bottom: 1px solid rgba(#000, 0.2);

			&:hover {
				background: #eee;
			}

			.id {
				font-weight: bold;
				flex-basis: 60px;
				flex-shrink: 0;
				color: #000;
			}
		}
	}
}