.sc-panel.fire-card-wrapper .fire-card .fire-card-content {

	.dynamic {
		display: flex;
		justify-content: space-between;

		& > .sp-tabs {
			font-size: 13px;

			.sp-tabs-content {
				height: calc(100vh - 270px);
				overflow-y: auto;
				padding-right: 15px;
				min-width: 400px;
			}
		}

		.section-title {
			font-weight: bold;
			margin-bottom: 10px;
		}

		.main-info {
			display: flex;

			.main-info-box {
				flex: 0 0 50%;

				.form-field {
					max-width: 70%;
				}
			}
		}

		.secondary-info {
			display: flex;

			&-container {
				flex-grow: 1;

				.threat-checkbox {
					display: flex;
					flex-direction: row-reverse;

					label {
						flex-grow: 1;
					}
				}
			}

			.jumping-info {
				display: flex;
				flex-grow: 1;

				.form-field {
					flex-grow: 1;
					&:first-child {
						padding-right: 10px;
					}
				}
			}
		}

		.row {
			//margin-left: 0;
			margin-right: 0;
		}


		.people, .vehicles {
			min-height: 20%;
			margin-bottom: 30px;
		}

		textarea {
			max-height: 300px;
		}

		input {
			font-size: 12px;
			height: 30px;
		}

		.area-info {
			.form-field {
				//margin-bottom: 3px;
			}
		}
	}
}

.edit-dynamic-people-popup,
.edit-dynamic-vehicle-popup {
	.sp-popup-close-btn {
		display: none;
	}

	.validation-error {
		margin-left: 15px;
		color: #d00;
		font-weight: bold;
	}
}

.squares-block {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: 1fr;
	grid-column-gap: 20px;

	.form-field {
		max-width: 70%;

		label {
			margin: 0;
		}
	}

	#box0, #box3, #box5 {
		border-color: var(--secondary);
	}

	#box8, #box10 {
		border-color: var(--contacted);
	}

	.box-color {
		&-blue {
			border-color: var(--secondary);
		}
		&-orange {
			border-color: var(--contacted);
		}
	}

	.box2 {
		grid-column-start: 4;
	}
	.box4 {
		grid-column-start: 3;
	}
	.box5 {
		grid-column-start: 1;
	}
	.box7 {
		grid-column-start: 4;
	}
	.box9 {
		grid-column-start: 3;
	}
	.box10 {
		grid-column-start: 1;
	}

	.ar-box2 {
		grid-column-start: 1;
	}
	.ar-box3 {
		grid-column-start: 3;
	}
	.ar-box5 {
		grid-column-start: 2;
	}
}

.connection-lines {
	position: relative;
}