.registry-field-edit-popup {
    .popup-registry-filter {
        margin-bottom: 3px;
    }
    .controls {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .top {
            display: flex;
            gap: 10px;
            button {
                padding-right: 0;
                padding-left: 0;
            }
        }
        .bottom {
            display: flex;
            gap: 10px;
        }
    }
}
