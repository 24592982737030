.analytics-tab1 {
	display: grid;
	grid-template-columns: 570px 425px 730px;
	grid-template-rows: 350px 350px 350px;
	gap: 20px;

	.execution-plan {
		grid-column-start: 1;
		grid-column-end: 3;
		grid-row-start: 2;
		grid-row-end: 4;
	}

	.summary {
		grid-column-start: 1;
		grid-column-end: 2;

		.sp-row > div {
			text-align: center;
		}
	}

	.card {
		padding: 20px;
		background: var(--white);
		border-radius: 5px;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	}

	hr {
		margin: 25px -20px;
	}

	h1 {
		text-transform: uppercase;
	}

	.summary {
		label {
			font-size: inherit !important;
		}

		span {
			font-weight: 600;
		}
	}

	.plan-total-table {
		overflow: auto;
	}

	.plan-map {
		.info {
			padding: 20px;
		}

		svg {
			path {
				cursor: pointer;

				&:hover {
					stroke: #d00;
					stroke-width: 2px;
				}
			}
		}
	}
}
