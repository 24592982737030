.appeal-categories {
	height: 100%;

	& > .categories-tree:first-child {
		padding-left: 0;
	}

	.categories-tree {
		height: calc(100% - 35px);
		overflow: auto;
		//background: #edd;
		//padding-left: 5ch;

		.category {
			line-height: 24px;

			.info {
				display: flex;
				align-items: flex-start;

				.code {
					flex-basis: 140px;
					flex-shrink: 0;
					font-family: monospace, monospace;
					color: #aaa;
					font-size: 14px;
				}

				.name {
					flex-grow: 1;
					cursor: pointer;
					font-size: 14px;
					text-decoration: underline;
					white-space: nowrap;

					&:hover {
						color: #0af;
					}

					.dots {
						em {
							display: inline-block;
							vertical-align: middle;
							width: 4px;
							height: 4px;
							border-radius: 50%;
							background: #ddd;
							margin-right: 10px;
						}
					}

				}

				.organization {
					flex-basis: 300px;
					flex-shrink: 1;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					color: #0af;
					font-size: 12px;
				}

				.period {
					flex-basis: 60px;
					flex-shrink: 0;
					text-align: center;
					color: #0af;
					font-size: 12px;
				}
			}

			&.last {
				.info .name {
					pointer-events: none;
					text-decoration: none;
				}
			}

			$shift: 20px;

			/*
			&.level-1 .name {
				margin-left: calc(#{$shift} * 1);
			}

			&.level-2 .name {
				margin-left: calc(#{$shift} * 2);
			}

			&.level-3 .name {
				margin-left: calc(#{$shift} * 3);
			}

			&.level-4 .name {
				margin-left: calc(#{$shift} * 4);
			}
			*/
		}
	}
}