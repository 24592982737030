.data-on-map {
	$hdr-font-size: 26px;
	$font-size: 18px;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	font-size: $font-size;
	line-height: 1.2em;
	pointer-events: none;
	z-index: 1;

	.block {
		position: absolute;
		background: var(--super-light-grey);
		border-radius: var(--border-radius);
		padding: 0 !important;

		table {
			border-collapse: collapse;
			padding: 0;
			margin: 0;

			thead tr th,
			tbody tr td {
				font-size: $font-size;
				padding: 0;
			}
		}

		.layer-item {
			display: flex;
			column-gap: 20px;
			align-items: center;

			img {
				height: 1em;
			}

			margin-bottom: 10px;
		}

		.block-hdr {
			background: var(--primary);
			color: #fff;
			padding: 0.7rem;
			font-size: $hdr-font-size;
		}

		.content {
			padding: 1rem;
		}
	}
}

.plan-export-popup {
	margin-top: -25px; // компенсируем половину высоты .actions

	.sp-popup-content {
		margin: -15px;
		width: calc(100% + 30px);
		overflow: visible;

		.actions {
			height: 50px;
			padding: 10px 15px;
			display: flex;
			align-items: center;
			gap: 10px;
			background: var(--dark-grey);
			color: #fff;
			border-top-left-radius: 5px;
			border-top-right-radius: 5px;
		}

		.preview {
			$height: 90vh; // высота контейнера карты
			width: 100vw;
			max-height: $height;
			position: relative;
			overflow: hidden;
			//max-width: calc(#{$height} * 297 / 210); // A4 width
			//height: calc(100vw * 210 / 297); // A4 height

			&,
			&.size-1200x800 {
				max-width: calc(#{$height} * 1200 / 800);
				height: calc(100vw * 800 / 1200);
			}

			&.size-800x1200 {
				max-width: calc(#{$height} * 800 / 1200);
				height: calc(100vw * 1200 / 800);
			}

			&.size-2200x1800 {
				max-width: calc(#{$height} * 2200 / 1800);
				height: calc(100vw * 1800 / 2200);
			}

			&.size-1800x2200 {
				max-width: calc(#{$height} * 1800 / 2200);
				height: calc(100vw * 2200 / 1800);
			}

			.export-map {
				transform-origin: left top;
				//width: 1754px;
				//height: 1240px;
				width: 100%;
				height: 100%;
				z-index: 3;

				.map-right-sidebar {
					display: none;
				}
			}

			.rendered-content {
				transform-origin: left top;
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 4;
				pointer-events: none;
			}

			.export-bg {
				position: absolute;
				background: #ccc;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 5px;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				z-index: 2;
				pointer-events: none;
			}
		}
	}
}

.export-table {
	position: absolute;
	font-size: 60px;
	background: #fff;
	box-sizing: content-box;

	.title {
		background: var(--primary);
		color: #fff;
		height: 1rem;
	}

	display: grid;
}
