.sp-table {
    .skeleton-row {
        width: 100%;
        height: auto;
        overflow: hidden;
        position: relative;
        max-width: 100vw;

        .number-value,
        .number-value,
        .date-value {
            display: none;
        }

        @keyframes shimmer {
            0% {
                transform: translate(-100%);
            }
            70%,
            100% {
                transform: translate(100%);
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background: linear-gradient(90deg, transparent, transparent, rgba(#29324b, 0.35), transparent, transparent);
            animation-name: shimmer;
            animation-duration: 2.3s;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
}
