.force-availability-popup {
	.form-content {
		display: flex;
		flex-direction: row;

		.right {
			height: 500px;
			margin-bottom: 20px;
			display: flex;
			flex-direction: column;
			& > * {
				padding-left: 30px;
			}
		}
	}

	.ui-pager {
		margin-top: 0;
	}
	.sp-popup-close-btn {
		display: none;
	}
	.controls {
		display: flex;
		flex-grow: 1;
		justify-content: flex-end;
	}
}

.force-availability-copy-popup {
	min-height: unset;
	.sp-popup-close-btn {
		display: none;
	}
	.controls {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 30px;
		.loader {
			margin-right: 40px;
		}
	}
}

.force-availability-page {
	height: 100%;
	display: flex;
	flex-direction: column;
	#table {
		height: unset;
		flex-grow: 1;
	}
}
